import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pregunta } from 'app/main/models/Pregunta';
import { environment } from 'environments/environment';

@Injectable()


export class EvaluacionDesempenoServices {

    //questionList: AngularDynamoList<any>;
    pregunta:Pregunta[]

    private httpOptions = { headers: new HttpHeaders({ 'Access-Control-Allow-Origin':'*' }) };
    

    constructor(private http:HttpClient){}

    getHeaders() {
        let accessToken = '';
        for (let a in localStorage) {
          if (a.endsWith('.accessToken'))
            accessToken = localStorage[a];
        }
    
        let headers_object = new HttpHeaders({
          'Content-Type': 'application/json',
           'Authorization': "Bearer "+ accessToken
        });
        return headers_object;
      }

    Url= `${environment.apiGeneral}/evaluacion/preguntas`;
    Urlpatch= `${environment.apiGeneral}/evaluacion`;

    

    getQuestion(nombreCompetencia){
        return this.http.get(this.Url+'?'+ "nombreCompetencia="+nombreCompetencia, {headers:this.getHeaders()});
    }

    patchQuestion(respuesta){
        console.log(respuesta)
        return this.http.patch<any>(this.Urlpatch,respuesta, {headers:this.getHeaders()});
    }

    putQuestion(respuesta){
      console.log(respuesta)
      return this.http.put<any>(this.Urlpatch,respuesta, {headers:this.getHeaders()});
  }
    


}