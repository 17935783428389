import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReporteIndiService {

  
  private endPointPersonasEtapa3:string = `${environment.apiPersonas}/administrador`;
  private endPointAreasyCargoEtapa3: string = `${environment.apiResultadosArea}`;
  private endPointAreasResultados: string = `${environment.apiResultadosA}`;
  private endPointCargosResultados: string = `${environment.apiResultados}/resultadosCorporativos`;
  private endPointCompResultados: string = `${environment.apiResultadosComp}`;

  constructor(private _http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

 

  /**
   * Metodo que obtiene los colaborados para el reporte individual etapa 3
   * @param limite
   * @returns 
   */
  obtenerColaboradores(datos){
    return this._http.post(this.endPointPersonasEtapa3, datos,{headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  obtenerAreas(){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointAreasyCargoEtapa3+'?'+ "lista="+"Area",{headers:this.getHeaders()})
  }

  obtenerCargos(){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointAreasyCargoEtapa3+'?'+ "lista="+"Cargo",{headers:this.getHeaders()})
  }

  resultadosAreas(dato){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointAreasResultados+'?'+ "Area="+dato,{headers:this.getHeaders()})
  }

  resultadosCargo(dato){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointCargosResultados+'?'+ "Cargo="+dato,{headers:this.getHeaders()})
  }

  resultadosCompetencia(){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointCompResultados+'?'+ "Reporte="+"competencias",{headers:this.getHeaders()})
  }
  


   /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
