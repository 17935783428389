import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthenticationService } from 'app/auth/service';
import { colors } from 'app/colors.const';
import { Colaborador } from 'app/main/models/colaborador';
import { GeneralResponse } from 'app/main/models/generalResponse';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { NovedadesService } from '../../etapa1/colaboradores/novedades.service';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { MonitoreoEtapa2Service } from './monitoreo-etapa2.service';

@Component({
  selector: 'app-monitoreo-etapa2',
  templateUrl: './monitoreo-etapa2.component.html',
  styleUrls: ['./monitoreo-etapa2.component.scss']
})
export class MonitoreoEtapa2Component implements OnInit {
  generalResponse:GeneralResponse;
  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  searchValue = '';
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  public selectStatus: any = [
    { name: 'Todo', value: null },
    { name: 'Finalizado', value: 'Finalizado' },
    { name: 'Pendiente', value: 'Pendiente' },
    { name: 'En curso', value: 'En curso' },
  ];
  rows: Colaborador[] = [];
  tempData =[];
  selectedStatus = null;
  isLoadingPage = false;
  evaluacionesFinalizadas:number;
  evaluacionesPendientes:number;
  creandoNovedad = false;
  public preferredUsername = this._authService.getAttributes()['preferred_username'];
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('goalChartRef') goalChartRef: any;

  data: any;
  goalChartoptions;
  isMenuToggled = false;
  $textHeadingColor = '#5e5873';
  $goalStrokeColor2 = '#51e5a8';
  $strokeColor = '#ebe9f1'; 

  constructor(private _coreConfigService: CoreConfigService, private _configuracionService:ConfigGeneralServices,
              private router:Router, private alert:SweetAlertService, private _monitoreoEtapa2:MonitoreoEtapa2Service,
              private _novedadesService:NovedadesService, private _authService:AuthenticationService) { 
    this.goalChartoptions = {
      chart: {
        height: 245,
        type: 'radialBar',
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      },
      colors: [this.$goalStrokeColor2],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '77%'
          },
          track: {
            background: this.$strokeColor,
            strokeWidth: '50%'
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              color: this.$textHeadingColor,
              fontSize: '2.86rem',
              fontWeight: '600'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.success],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      grid: {
        padding: {
          bottom: 30
        }
      }
    };
  }

  ngOnInit(): void {
    this.data = {goalOverview: {series: [0]}}
    this.isLoadingPage = true;
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 1"){
          if(this.payload == "Administradores"){
            this.initContentHeaderAndColaboradores();
          }else{
            this.alert.alertNotification('info', 'La etapa 2 estará activa desde ' + data['fechas']['inicioEtapa2'] + ' hasta ' + data['fechas']['finEtapa2'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initContentHeaderAndColaboradores();
        }
      }
    },err => {
      console.log(err);
    })    
  }

  getContentHeader() {
    this.contentHeader = {
      headerTitle: 'Monitoreo etapa 2',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Monitoreo',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
  }

  initContentHeaderAndColaboradores(){
    this.getContentHeader();
    this.data = {goalOverview: {series: [0],}}
    this._monitoreoEtapa2.obtenerColaboradores(700).subscribe(data => {
      if(data != null){
        this.rows = data[0]['datos'];
        this.tempData = this.rows;
        this.evaluacionesFinalizadas = data[1]['evaluacionesFinalizadas'];
        this.evaluacionesPendientes = data[1]['evaluacionesPendientes'];
        this.isLoadingPage = false;
        if(data[1]['porcentaje'] <= 100)
          this.data = {goalOverview: {series: [data[1]['porcentaje']]}}
      }
    },err => {
      console.log(err);
      this.isLoadingPage = false;
    })
  }

  crearNovedadDescargar(){
    this.creandoNovedad = true;
    let request:any = this.buildRequestDescargar();
    this._novedadesService.sendNovedad(request).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if(this.generalResponse.result.estado != 'Exitoso'){
        this.creandoNovedad = false;
        this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
        return;
      }
      this.alert.alertNotification('success', 'Nueva novedad con ID:  #' + this.generalResponse.payload['novedades'][0]['idProceso'], 7000);
      this.router.navigate(['/novedades']);
      this.creandoNovedad = false;
    },err => {
      console.error(err);
      this.creandoNovedad = false;
      this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
    })
  }

  buildRequestDescargar():any{
    let request: any = {
      "payload": {
        "novedades": [
          {
            "idColaborador": Number(this.preferredUsername),
            "proceso": "RP",
            "tipo": "Monitoreo Etapa 2",
            "url": environment.apiMonitoreo + '/etapa2?limite=700'
          }
        ]
      }
    }
    return request;
  }

  showMessageNotRows(){
    this.alert.alertNotification('info', 'No hay registros para la descarga.', 5000);
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;

    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
        return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : null;
    if (filterStatus != null) filterStatus = filterStatus;

    filterAllData = filterAllData.filter(row => {
      return (row.estadoEtapa2 === filterStatus) || filterStatus == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }

  /**
  * After View Init
  */
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (
        (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) &&
        localStorage.getItem('currentUser')
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.goalChartoptions.chart.width = this.goalChartRef?.nativeElement.offsetWidth;
        }, 500);
      }
    });
  }
}
