import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { Pregunta, DatosObj } from 'app/main/models/Pregunta';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import Swal from 'sweetalert2';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { EvaluacionDesempenoServices} from '../evaluacion-desempeno/evaluacion-desempeno.service';

@Component({
  selector: 'app-evaluacion-desempeno',
  templateUrl: './evaluacion-desempeno.component.html',
  styleUrls: ['./evaluacion-desempeno.component.scss']
})
export class EvaluacionDesempenoComponent implements OnInit {

  chequeo=true;

  preguntas:Pregunta
  datosObj:DatosObj
  datos: Array<DatosObj>
  nombreCompetenciainitial : string ;
  numeroPregunta: number = 1;
  leftView: String = "card-bodyleft";
  evaluacion!: {ID_evaluado: string,ID_evaluador: string,nombreEvaluado: string, competenciap:string};
  selectedQuestion: Array<any>;
  urlImage: string= "https://imagenes-funcionarios.s3.amazonaws.com/";

  idEvaluado: string ;
  idEvaluador: string ;
  competencia: string ;
  idCompetenciaItem: Number[];
  idCompetencia: Number;
  imgID_evaluado: string;
  showEvaluacion = false;

  respuesta: any
  estadoEncuesta:String ="Sin iniciar"
  descripcionItem: string[] ;
  descripcion: string;
  devolver=true;
  respRadio=false;
  cargandoPregunta=false;
  payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  constructor(private service: EvaluacionDesempenoServices, private router:Router,private rutaActiva: ActivatedRoute, public fb: FormBuilder,
              private _configuracionService:ConfigGeneralServices, private alert:SweetAlertService) { }

  async ngOnInit(){
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 1"){
          if(this.payload != "Administradores"){
            this.alert.alertNotification('info', 'La etapa 2 estará activa desde ' + data['fechas']['inicioEtapa2'] + ' hasta ' + data['fechas']['finEtapa2'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }
      }
    },err => {
      console.log(err);
    })  
    window.scrollTo(0,0);

    //params
    this.evaluacion = {
      ID_evaluado:  this.rutaActiva.snapshot.params.ID_evaluado,
      ID_evaluador:  this.rutaActiva.snapshot.params.ID_evaluador,
      nombreEvaluado: this.rutaActiva.snapshot.params.nombreEvaluado,
      competenciap: this.rutaActiva.snapshot.params.competenciap

    };  
    this.rutaActiva.params.subscribe(
      (params: Params)=>{
        this.evaluacion.ID_evaluado = params.ID_evaluado;
        this.evaluacion.ID_evaluador = params.ID_evaluador;
        this.evaluacion.nombreEvaluado = params.nombreEvaluado;
        this.evaluacion.competenciap = params.competenciap;
        this.imgID_evaluado = params.ID_evaluado;
        this.idEvaluado = params.ID_evaluado;
        this.idEvaluador = params.ID_evaluador;
        this.nombreCompetenciainitial = params.competenciap;

      }
    );

    //servicioPregunta
    this.cargandoPregunta = true;
    await this.service.getQuestion(this.nombreCompetenciainitial).toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;})
    this.datos = this.preguntas.datos
    this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
    this.datos = this.preguntas.datos
    this.descripcion = this.datos[0].descripcionCompetencia
    
    console.log(this.datos)

    if(this.nombreCompetenciainitial == "Desempeño del Cargo"){
      this.idCompetencia = 7;
      this.competencia = "Desempeño del Cargo";

    } else if(this.nombreCompetenciainitial == "Innovación"){
      this.idCompetencia = 6;
      this.competencia = "Innovación";
      this.leftView = "card-bodyleft2";

    } else if(this.nombreCompetenciainitial == "Desempeño Superior"){
      this.idCompetencia = 5;
      this.competencia = "Desempeño Superior";
      this.leftView = "card-bodyleft3";
    }
    else if(this.nombreCompetenciainitial == "Corresponsabilidad"){
      this.idCompetencia = 4;
      this.competencia = "Corresponsabilidad";
      this.leftView = "card-bodyleft";
    }
    else if(this.nombreCompetenciainitial == "Liderazgo Transformador"){
      this.idCompetencia = 3;
      this.competencia = "Liderazgo Transformador";
      this.leftView = "card-bodyleft2";
    }
    else if(this.nombreCompetenciainitial == "Agilidad Organizacional"){
      this.idCompetencia = 2;
      this.competencia = "Agilidad Organizacional";
      this.leftView = "card-bodyleft3";
    }
    else if(this.nombreCompetenciainitial == "Flexibilidad al Cambio"){
      this.idCompetencia = 1;
      this.competencia = "Flexibilidad al Cambio";
      this.leftView = "card-bodyleft";
    }
    

  }

  form = new FormGroup({

    pregunta: new FormControl('', Validators.required)

  });


  templateForm(value: any) {

    let datosPregunta: any = {
      idCompetencia: this.idCompetencia,
      competencia: this.competencia,
      respuesta: [value]
      };

      let respuestaObj: any ={
        ID_evaluado: Number(this.idEvaluado),
        ID_evaluador: Number(this.idEvaluador),
        datos:[ datosPregunta
        ]
  
      };

      this.respuesta = respuestaObj

      if (datosPregunta!==""){

      }
    
    console.log(this.idCompetencia)

  }


  /**async devolverPregunta(){
    switch(this.nombreCompetenciainitial){
      case "Flexibilidad al Cambio":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Agilidad Organizacional").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        await this.visualizarEvaluadosService.getQuestionProgreso(Number(this.idEvaluado),Number(this.idEvaluador)).toPromise().then((result: requestObtenerpregunta)=>{this.pregunta=result})
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft3";
          this.nombreCompetenciainitial = "Agilidad Organizacional";
          this.competencia = "Agilidad Organizacional";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.devolver = true;
      break;
      case "Agilidad Organizacional":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Liderazgo Transformador").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
          this.datos = this.preguntas.datos
          this.descripcion = this.datos[0].descripcionCompetencia
          this.leftView = "card-bodyleft2";
          this.nombreCompetenciainitial = "Liderazgo Transformador";
          this.competencia = "Liderazgo Transformador";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.devolver = true;

      break;
      case "Liderazgo Transformador":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Corresponsabilidad").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft";
          this.nombreCompetenciainitial = "Corresponsabilidad";
          this.competencia = "Corresponsabilidad";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.devolver = true;
      break;
      case "Corresponsabilidad":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Desempeño Superior").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft3";
          this.nombreCompetenciainitial = "Desempeño Superior";
          this.competencia = "Desempeño Superior";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.devolver = true;
      break;
      case "Desempeño Superior":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Innovación").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
          this.datos = this.preguntas.datos
          this.descripcion = this.datos[0].descripcionCompetencia
          this.leftView = "card-bodyleft2"; 
          this.nombreCompetenciainitial = "Innovación";
          this.competencia = "Innovación";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.devolver = true;
      break;
      case "Innovación":
        this.cargandoPregunta = true;
        await this.service.getQuestion("Desempeño del Cargo").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        await this.visualizarEvaluadosService.getQuestionProgreso(Number(this.idEvaluado),Number(this.idEvaluador)).toPromise().then((result: requestObtenerpregunta)=>{this.pregunta=result})
        console.log(this.pregunta.datos)
          this.datos = this.preguntas.datos
          this.descripcion = this.datos[0].descripcionCompetencia
          this.leftView = "card-bodyleft"; 
          this.nombreCompetenciainitial = "Desempeño del Cargo";
          this.competencia = "Desempeño del Cargo";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];   
          this.devolver=false;
      break;

    }
  }**/


  async enviarRespuesta(){

    switch(this.nombreCompetenciainitial){
      case "Desempeño del Cargo":
        await this.service.getQuestion("Desempeño del Cargo").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft"; 
        let lengthRespuesta = this.respuesta['datos'][0]['respuesta'];
        if(lengthRespuesta[0][1] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Innovación").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
          this.datos = this.preguntas.datos
          this.descripcion = this.datos[0].descripcionCompetencia
          this.leftView = "card-bodyleft2"; 
          this.nombreCompetenciainitial = "Innovación";
          this.competencia = "Innovación";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;     
          window.scrollTo(0,0);
        }
      break;

      case "Innovación":
        await this.service.getQuestion("Innovación").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia 
        let lengthRespuesta2 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft2";
        if(lengthRespuesta2[0][1] === "" || lengthRespuesta2[0][2] === "" || lengthRespuesta2[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Desempeño Superior").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft3";
          this.nombreCompetenciainitial = "Desempeño Superior";
          this.competencia = "Desempeño Superior";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          console.log(this.respuesta)
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
        }

      break;

      case "Desempeño Superior":
        await this.service.getQuestion("Desempeño Superior").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia 
        let lengthRespuesta3 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft3";
        if(lengthRespuesta3[0][1] === "" || lengthRespuesta3[0][2] === "" || lengthRespuesta3[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Corresponsabilidad").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft";
          this.nombreCompetenciainitial = "Corresponsabilidad";
          this.competencia = "Corresponsabilidad";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
        }

      break;

      case "Corresponsabilidad":
        await this.service.getQuestion("Corresponsabilidad").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia 
        let lengthRespuesta4 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft";
        if(lengthRespuesta4[0][1] === "" || lengthRespuesta4[0][2] === "" || lengthRespuesta4[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Liderazgo Transformador").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
          this.datos = this.preguntas.datos
          this.descripcion = this.datos[0].descripcionCompetencia
          this.leftView = "card-bodyleft2";
          this.nombreCompetenciainitial = "Liderazgo Transformador";
          this.competencia = "Liderazgo Transformador";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
        }

      break;

      case "Liderazgo Transformador":
        await this.service.getQuestion("Liderazgo Transformador").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia 
        let lengthRespuesta5 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft2";
        if(lengthRespuesta5[0][1] === "" || lengthRespuesta5[0][2] === "" || lengthRespuesta5[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Agilidad Organizacional").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft3";
          this.nombreCompetenciainitial = "Agilidad Organizacional";
          this.competencia = "Agilidad Organizacional";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
        }

      break;

      case "Agilidad Organizacional":
        await this.service.getQuestion("Agilidad Organizacional").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia 
        let lengthRespuesta6 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft3";
        if(lengthRespuesta6[0][1] === "" || lengthRespuesta6[0][2] === "" || lengthRespuesta6[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.cargandoPregunta = true
          await this.service.getQuestion("Flexibilidad al Cambio").toPromise().then((result: Pregunta)=>{this.preguntas=result; this.cargandoPregunta=false;});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        this.leftView = "card-bodyleft";
          this.nombreCompetenciainitial = "Flexibilidad al Cambio";
          this.competencia = "Flexibilidad al Cambio";
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.service.patchQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
        }

      break;

      case "Flexibilidad al Cambio":
        await this.service.getQuestion("Flexibilidad al Cambio").toPromise().then((result: Pregunta)=>{this.preguntas=result});
        this.datos = this.preguntas.datos
        this.descripcion = this.datos[0].descripcionCompetencia
        let lengthRespuesta7 = this.respuesta['datos'][0]['respuesta'];
        this.leftView = "card-bodyleft";
        if(lengthRespuesta7[0][1] === "" || lengthRespuesta7[0][2] === "" || lengthRespuesta7[0][3] === ""){
          Swal.fire({
            title: 'Error',
            text: "Tienes preguntas sin responder",
            icon: 'error',
            confirmButtonColor: '#0033a1',
            confirmButtonText: 'Inténtalo de Nuevo'
          })
        }else{
          this.idCompetenciaItem = this.preguntas.datos.map(item=>item.idCompetencia)
          this.idCompetencia = this.idCompetenciaItem[0];
          this.leftView = "card-bodyleft";
          this.service.putQuestion(this.respuesta).subscribe(data=>{ })
          this.datos = this.preguntas.datos;
          window.scrollTo(0,0);
          let urlEvaluados: string = "evaluacion"
        Swal.fire({
          title: 'Evaluación finalizada',
          text: "Has finalizado la evaluación",
          icon: 'success',
          confirmButtonColor: '#0033a1',
          confirmButtonText: 'Continuar'
        }).then(()=>{
          this.router.navigate([urlEvaluados]);
        })
        }
        

      break;

    }



  }

  

}


