import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VerplanserviceService} from '../verplanservice.service';
import { Plan, DatosObj } from 'app/main/models/planDesarrollo';

@Component({
  selector: 'app-miplan',
  templateUrl: './miplan.component.html',
  styleUrls: ['./miplan.component.scss']
})
export class MiplanComponent implements OnInit {

  public contentHeader: object;
  idColaborador : number ;
  plan: Plan
  datos: Array<DatosObj>
  nombre: string
  attributes360:any;
  usuario!:{idUsuario:string}
  cargandoPlan = true;
  cargaPlan = false;


  constructor( private router:Router,private service: VerplanserviceService,private rutaActiva: ActivatedRoute) { }

  ngOnInit() {
    this.cargandoPlan = true;
  this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
  this.idColaborador = Number(this.attributes360['preferred_username']);
    

    this.service.obtenerResultados(this.idColaborador).toPromise().then((result: Plan)=>{
      this.plan=result; 
      this.datos = this.plan.datos
      this.cargandoPlan = false;
      this.cargaPlan = true;
    
    })
    
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Reporte individual',
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'reportes',
            isLink: true,
            link: '/'
          },
          {
            name: 'colaborador',
            isLink: true,
            link: '/'
          }
        ]
      }
    };
   }
}
