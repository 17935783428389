import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthenticationService } from 'app/auth/service';
import { GeneralResponse } from 'app/main/models/generalResponse';
import { ResultadosResponse } from 'app/main/models/resultadosResponse';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { NovedadesService } from '../../etapa1/colaboradores/novedades.service';
import { ResultadosService } from './resultados.service';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss']
})
export class ResultadosComponent implements OnInit {
  public generalReponse:GeneralResponse;
  public contentHeader: object;
  public generalResponse:GeneralResponse;
  public limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  public selectedOption: number = 15;
  public searchValue = '';
  public rows: ResultadosResponse[] = [];
  public tempData = this.rows;
  public creandoNovedad:boolean = false;
  public isLoadingPage:boolean = false;
  public preferredUsername = this._authService.getAttributes()['preferred_username'];
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
  public areas:any[] =[];
  public selectedArea = null;
  public url = environment.apiResults;


  public ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private _novedadesService:NovedadesService, private _authService:AuthenticationService,
              private alert:SweetAlertService, private router:Router, private _resultadosService:ResultadosService) { }

  ngOnInit(): void {
    this.getContentHeader();
    this.getResultados();
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Resultados',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: false,
          },
        ]
      }
    }
  }

  crearNovedadDescargar(){
    this.creandoNovedad = true;
    let request:any = this.buildRequestDescargar();
    console.log(request);
    this._novedadesService.sendNovedad(request).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if(this.generalResponse.result.estado != 'Exitoso'){
        this.creandoNovedad = false;
        this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
        return;
      }
      this.alert.alertNotification('success', 'Se a creado una novedad para la descarga del archivo con el id: ' + this.generalResponse.payload['novedades'][0]['idProceso'], 7000);
      this.router.navigate(['/novedades']);
      this.creandoNovedad = false;
    },err => {
      console.error(err);
      this.creandoNovedad = false;
      this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
    })
  }

  buildRequestDescargar():any {
    let url:string = this.urlJefeOrAdministrador();
    if(this.selectedArea != null) {
      let request: any = {
        "payload": {
          "novedades": [
            {
              "idColaborador": Number(this.preferredUsername),
              "proceso": "RP",
              "tipo": "Resultado Evaluación 360",
              "url": url,
              "area": this.selectedArea
            }
          ]
        }
      }
      return request;
    }

    let request: any = {
      "payload": {
        "novedades": [
          {
            "idColaborador": Number(this.preferredUsername),
            "proceso": "RP",
            "tipo": "Resultado Evaluación 360",
            "url": url,
          }
        ]
      }
    }
 
    return request;
  }

  urlJefeOrAdministrador():string{
    if(this.payload == 'Jefes') {
      this.url += '/lider/resultados?idColaborador='+this.preferredUsername;
    }else if(this.payload == 'Administradores') {
      this.url += '/admin/resultados';
    }
    return this.url;
  }

  getResultados(){
    this.isLoadingPage = true;
    this._resultadosService.getResultados(this.payload, this.preferredUsername).subscribe(data => {
      this.generalReponse = data as GeneralResponse;
      this.areas = this.generalReponse.payload['areas'];
      this.rows = this.generalReponse.payload['colaboradores'];
      this.tempData = this.rows;
      this.isLoadingPage = false;
    },err => {
      console.error(err);
      this.isLoadingPage = false;
      this.alert.alertNotification('error', 'Error al cargar los resultados.', 5000);
    })
  }

  filterAll() {
    console.log(this.selectedArea);
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;

    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
        return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1 ||
                d.cargo && d.cargo.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterArea = this.selectedArea ? this.selectedArea : null;
    if (filterArea != null) filterArea = filterArea;

    filterAllData = filterAllData.filter(row => {
      return (row.area === filterArea) || filterArea == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  showMessageNotRows(){
    this.alert.alertNotification('info', 'No hay resultados para la descarga.', 5000);
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}