import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { menuAdministrador, menuJefe, menuUsuario } from 'app/menu/menu';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreMenu, CoreMenuItem } from '@core/types';
import { ConfigGeneralServices } from 'app/main/dashboard/etapa1/configuracion/configgeneral.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  menu: CoreMenu[];
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _coreMenuService: CoreMenuService,
              private _configuracionService:ConfigGeneralServices) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roleExpected = route.data['roles'];
    const role = this._authenticationService.getPayload();
    this._authenticationService.validateLogoutCognito();
    return this._authenticationService.isAuthenticated().pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this._router.navigate(['/pages/authentication/login-v1'], { queryParams: { returnUrl: state.url } });
        } else {
          if (localStorage.getItem('payload') != null) {
            this.getConfiguration();
          }

          if(roleExpected.length === 3){
            if(roleExpected[0] === role['cognito:groups'][0] ||
              roleExpected[1] === role['cognito:groups'][0] ||
              roleExpected[2] === role['cognito:groups'][0]){
              return true;
            }else{
              this._router.navigate(['/pages/miscellaneous/error-autorizado']);
              return false;
            }
          }else if(roleExpected.length === 2){
            if(roleExpected[0] === role['cognito:groups'][0] ||
              roleExpected[1] === role['cognito:groups'][0]){
              return true;
            }else{
              this._router.navigate(['/pages/miscellaneous/error-autorizado']);
              return false;
            }
          }else{
            if(roleExpected[0] === role['cognito:groups'][0]){
              return true;
            }else{
              this._router.navigate(['/pages/miscellaneous/error-autorizado']);
              return false;
            }
          }
        }
      })
    );
  }

  getConfiguration(){
    let payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        this.menu = payload === "Administradores" ? menuAdministrador : payload === "Jefes" ? menuJefe : menuUsuario;
        let etapaActual = data['etapaActual'];
        if(payload != "Administradores"){
          if(etapaActual === "Etapa 1"){
            this.disableChildrens(1);
          } else if(etapaActual === "Etapa 2"){
            this.disableChildrens(2);
          }
        }
        this._coreMenuService.unregister('main');
        this._coreMenuService.register('main', this.menu);
        this._coreMenuService.setCurrentMenu('main');
      }
    },err => {
      console.log(err);
      console.log('Error al obtener los datos de configuracion');
    })
  }

  disableChildrens(etapa:number){
    for(let i = etapa; i < this.menu.length; i++){
      let childrens:CoreMenuItem[] = this.menu[i].children;
      childrens.forEach(function(children) {
        children.disabled = true;
      });
    }
  }
}
