import { HttpClient, HttpErrorResponse, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';

@Injectable()


export class ConfigGeneralServices {

    endPointConfiguracion:string = environment.apiConfiguracion;

    private httpOptions = { headers: new HttpHeaders({ 'Access-Control-Allow-Origin':'*' }) };
    
    constructor(private http:HttpClient){}

    getHeaders() {
        let accessToken = '';
        for (let a in localStorage) {
          if (a.endsWith('.accessToken'))
            accessToken = localStorage[a];
        }
    
        let headers_object = new HttpHeaders({
          'Content-Type': 'application/json',
           'Authorization': "Bearer "+ accessToken
        });
        return headers_object;
      }

    patchConfig(datosConfig){
      console.log(datosConfig)
        return this.http.patch<any>(this.endPointConfiguracion,datosConfig, {headers:this.getHeaders()});
    }

    getConfiguration(){
      return this.http.get(this.endPointConfiguracion,{headers:this.getHeaders()});

    }
    

  /**
  * Catch errors on call WS
  * @param error
  */
   private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}