<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="invoice-list-wrapper">
            <div class="card">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <label class="d-flex align-items-center">Ver&nbsp;
                                    <select class="form-control mx-20" [(ngModel)]="selectedOption" (ngModelChange)="changePageSize($event)">
                                        <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 col-12 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="row pb-md-0 align-items-center w-100">
                            <div class="col-sm-12 col-md-4 pr-0">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-0 mr-0 pr-0" *ngIf="payload == 'Administradores' || payload == 'Jefes'">
                                    <ng-select [items]="areas" [(ngModel)]="selectedArea" bindLabel="name"
                                        placeholder="Seleccione una Área" (change)="filterAll()"></ng-select>
                                </fieldset>
                            </div> 
                            <div class="col-sm-12 col-md-4 pr-0">
                                <button type="button" disabled title="No hay resultados para la descarga." (click)="showMessageNotRows()" class="btn btn-primary form-control" rippleEffect *ngIf="rows.length == 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button type="button" class="btn btn-primary form-control" rippleEffect (click)="crearNovedadDescargar()" *ngIf="!creandoNovedad && rows.length > 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button class="btn btn-primary form-control" type="button" rippleEffect *ngIf="creandoNovedad && rows.length > 0">
                                    <span class="ml-25 align-middle">Descargando</span>
                                    <span class="spinner-border spinner-border-sm" role="status" style="margin-left: 5px;" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="col-sm-12 col-md-4 ml-md-0 my-sm-1 ml-sm-1 my-1 ml-1 mr-0 pr-0 pr-sm-1">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search"
                                        class="form-control" placeholder="Búsqueda" (keyup)="filterAll()" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Table-->
                <ngx-datatable [rows]="rows"  [rowHeight]="'auto'" class="bootstrap core-bootstrap" [limit]="selectedOption"
                    [columnMode]="ColumnMode.force" [headerHeight]="200" [footerHeight]="50" [scrollbarH]="true"
                    [messages]="{emptyMessage: ''}" >
                    <ngx-datatable-column name="Nombre Colaborador" prop="nombre" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template >
                            <div class="nombre">
                                {{row?.nombre}}
                            </div> 
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Cargo" prop="cargo" [width]="170">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="cargo">
                                {{row?.cargo }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Calificación Total" prop="calificacion" [width]="170">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="calificacion">
                                {{row?.calificacion}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Desempeño Superior" prop="desemSuperior" [width]="190">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="desemSuperior">
                                {{row?.desemSuperior}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Flexibilidad al Cambio" prop="flexibilidad" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="flexibilidad">
                                {{row?.flexibilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Innovación" prop="innovacion" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="innovacion">
                                {{row?.innovacion}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Corresponsable" prop="corresponsabilidad" [width]="160">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="corresponsabilidad">
                                {{row?.corresponsabilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Liderazgo Transformador" prop="liderazgo" [width]="240">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="liderazgo">
                                {{row?.liderazgo}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Agilidad Organizacional" prop="agilidad" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="agilidad">
                                {{row?.agilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Desempeño Cargo" prop="desemCargo" [width]="180">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="desemCargo">
                                {{row?.desemCargo}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column class="calificacionAnterior" name="Calificacion Anterior" prop="calificacionAnterior" [width]="240">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="calificacionAnterior">
                                {{row?.calificacionAnterior}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                                Total de registros {{rowCount}}
                            </div>
                            <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                                No hay registros ...
                            </div>
                            <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                                Cargando información...
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [count]="rowCount" [page]="curPage" [size]="pageSize" (change)="onChange($event)"
                                [hidden]="!((rowCount / pageSize) > 1)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>