import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()


export class VisualizarEvaluadosServices {

    private httpOptions = { headers: new HttpHeaders({ 'Access-Control-Allow-Origin':'*' }) };
    

    constructor(private http:HttpClient){}

    getHeaders() {
      let accessToken = '';
      for (let a in localStorage) {
        if (a.endsWith('.accessToken'))
          accessToken = localStorage[a];
      }
  
      let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
         'Authorization': "Bearer "+ accessToken
      });
      return headers_object;
    }

    Url=`${environment.apiGeneral}/evaluados`;
    urlDes=`${environment.apiGeneral}/evaluados/inhabilitar`;

    patchDeshabilitar(datosMotivo){
      console.log(datosMotivo)
        return this.http.patch<any>(this.urlDes,datosMotivo, {headers:this.getHeaders()});
    }

    


    getEvaluadosPorIdUsuario(idEvaluador:number){
        let params= new HttpParams().set('estado',"Aprobado").set('ID_evaluador',idEvaluador);
        
        return this.http.get(this.Url, {params: params, headers:this.getHeaders()}).pipe(
          tap(response => {
            if(response){
            }
          }),
          catchError(this.handleError)
        )
      }

      urlPro =`${environment.apiGeneral}/evaluacion`;

      getQuestionProgreso(idEvaluado:number,idEvaluador:number){
        let params= new HttpParams().set('idEvaluado',idEvaluado).set('idEvaluador',idEvaluador);
        
        return this.http.get(this.urlPro, {params: params, headers:this.getHeaders()}).pipe(
          tap(response => {
            if(response){
            }
          }),
          catchError(this.handleError)
        )
    }

      private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error('Ocurrió un error:', error.error.message);
        }
        return throwError(error);
      }

      


}