<div class="content-wrapper container-xxl p-0">
  <div class="content-body" id="htmlData">
    <!-- Content-Header Component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="col-sm-12 col-md-24 d-flex align-items-center justify-content-center" *ngIf="cargandoReporte">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="q" *ngIf="content">
      <div class="col-md-24" id="pag1">
        <!--Datos funcionario-->
        <section>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header" style="justify-content: center;">
                  <div class="row">
                    <div>
                      <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">Reporte Individual</span><br>
                      <h6 class="font-weight-bolder" style="margin-left: 25%;">Datos del Colaborador</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 col-6">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th style="border: 0;">Nombre del Colaborador</th>
                              <th style="border: 0;">
                                <h6>{{nombre}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cédula</th>
                              <th style="border: 0;">
                                <h6>{{cedula}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cargo del Colaborador</th>
                              <th style="border: 0;">
                                <h6>{{cargof}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Área</th>
                              <th style="border: 0;">
                                <h6>{{area}}</h6>
                              </th>
                            </tr>
  
                          </tbody>
                        </table>
                      </div>
  
                    </div>
                    <div class="col-xl-6 col-6">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th style="border: 0;">Nombre del Líder</th>
                              <th style="border: 0;">
                                <h6>{{nombreJefe}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cargo del Líder</th>
                              <th style="border: 0;">
                                <h6>{{cargolider}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Período</th>
                              <th style="border: 0;">
                                <h6>{{periodo}}</h6>
                              </th>
                            </tr>
  
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Datos funcionario End-->
        <!--Titulo-->
        <section>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header" style="justify-content: center;">
                  <div class="row">
                    <div>
                      <h3 class="font-weight-bolder">Resumen de competencias</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Titulo End-->
  
        <!-- Tabla 1-->
        <section id="bar-and-hori-bar-charts">
          <div class="row">
            <div class="col-xl-12 col-24">
              <div class="card">
                <div class="row" id="table-contextual">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-left">
                          <h4 class="card-title font-weight-bolder">Total Evaluado: {{totalEvaluacion}}</h4>
  
                        </div>
  
                      </div>
  
                      <div class="card-body">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Competencia</th>
                                <th>Calificación</th>
                                <th>Peso</th>
                                <th>Puntaje ponderado</th>
                                <th>Promedio Organización</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default" >
                                <td>{{this.lista[0].nombreCompetencia}}</td>
                                <td> {{this.lista[0].calificacion}}</td>
                                <td> {{this.lista[0].peso}}</td>
                                <td>{{this.lista[0].puntajePonderado}}</td>
                                <td >{{this.lista[0].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[1].nombreCompetencia}}</td>
                                <td> {{this.lista[1].calificacion}}</td>
                                <td> {{this.lista[1].peso}}</td>
                                <td>{{this.lista[1].puntajePonderado}}</td>
                                <td >{{this.lista[1].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[2].nombreCompetencia}}</td>
                                <td> {{this.lista[2].calificacion}}</td>
                                <td> {{this.lista[2].peso}}</td>
                                <td>{{this.lista[2].puntajePonderado}}</td>
                                <td >{{this.lista[2].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[3].nombreCompetencia}}</td>
                                <td> {{this.lista[3].calificacion}}</td>
                                <td> {{this.lista[3].peso}}</td>
                                <td>{{this.lista[3].puntajePonderado}}</td>
                                <td >{{this.lista[3].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[4].nombreCompetencia}}</td>
                                <td> {{this.lista[4].calificacion}}</td>
                                <td> {{this.lista[4].peso}}</td>
                                <td>{{this.lista[4].puntajePonderado}}</td>
                                <td >{{this.lista[4].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[5].nombreCompetencia}}</td>
                                <td> {{this.lista[5].calificacion}}</td>
                                <td> {{this.lista[5].peso}}</td>
                                <td>{{this.lista[5].puntajePonderado}}</td>
                                <td >{{this.lista[5].promedioOrganizacion}}  </td>                              
                              </tr>
                              <tr class="table-default" >
                                <td>{{this.lista[6].nombreCompetencia}}</td>
                                <td> {{this.lista[6].calificacion}}</td>
                                <td> {{this.lista[6].peso}}</td>
                                <td>{{this.lista[6].puntajePonderado}}</td>
                                <td >{{this.lista[6].promedioOrganizacion}}  </td>                              
                              </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Tabla 1 End -->
  
          </div>
        </section>
        <!-- Bar Chart Start -->
        <section id="competencia 1">
          <div class="row">
  
            <div class="col-xl-12 col-24">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="header-left">
                    <h4 class="card-title">Competencias</h4>
                  </div>
  
                </div>
                <div class="card-body">
                  <div id="chartjs-bar-chart">
                    <canvas id="barChartCanva" baseChart #barChartRef height="400" [datasets]="barChart.datasets"
                      [labels]="barChart.labels" [options]="barChart.options" [legend]="barChart.legend"
                      [chartType]="barChart.chartType" [plugins]="plugins">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Bar Chart End -->
          </div>
        </section>
      </div>
  
      <!--Titulo2-->
      <section>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header" style="justify-content: center;">
                <div class="row">
                  <div>
                    <h3 class="font-weight-bolder">Resumen detallado por competencias</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Titulo2 End-->
  
      <div class="col-md-24" id="pag2">
        <!--dSuperior-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Desempeño Superior</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.DesemSuperiortotalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <br>
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">1. Transacción extra:</h6> Se destaca por implementar acciones que exceden las expectativas, generando valor agregado al logro de los objetivos de su Equipo y de la compañía.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">2. Compromiso:</h6> Se empodera e involucra de manera activa, enérgica y propositiva en todas las actividades y proyectos que tiene a cargo, reflejando un óptimo desempeño y alta productividad.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart2.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">3. Resolución de obstáculos:</h6> Muestra conocimientos y habilidades superiores para resolver problemas de su especialidad y de otros asuntos.  Y, cuando no son de su especialidad, muestra su capacidad resolutiva antes de realizar escalamientos.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>

                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].totalpregunta}}</h3>
  
                    </div>
                  </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart3.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--dSuperior-->
      </div>
  
      <div class="col-md-24" id="pag3">
        <!--Flexibilidad al Cambio-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Flexibilidad al Cambio</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.FlexibilidadtotalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <br>
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">1. Capacidad para anticiparse:</h6> Tiene un conocimiento del mercado y del negocio que le permite anticipar con facilidad los cambios de contexto, de prioridades y de relaciones.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart4.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">2. Apertura mental:</h6>  Analiza, valora y adopta puntos de vista o criterios diversos y los integra o aplica con facilidad en sus propuestas, procesos y relaciones.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart5.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">3. Capacidad para el cambio:</h6> Logra cambiar procesos, servicios, relaciones o comportamientos para potenciar la experiencia de los usuarios finales (internos y/o externos).</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].totalpregunta}}</h3>
  
                    </div>
                  </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart6.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--Flexibilidad al Cambio-->
      </div>
  
      <div class="col-md-24" id="pag4">
        <!--Innovación-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Innovación</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.InnovaciontotalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <br>
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">1. Creatividad: </h6>Identifica e implementa formas novedosas, más simples y prácticas para resolver necesidades del negocio.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart7.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6> <h6  class="card-subtitle  mb-25 font-weight-bolder">2. Orientación al aprendizaje:</h6> Demuestra motivación para seguir aprendiendo, investigando y descubriendo nuevos modelos o ideas, para generar valor agregado a los clientes, aliados y a la compañía.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart8.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">3. Capacidad para la transformación:</h6> Logra hacer que las cosas pasen para generar cambios que promuevan la transformación sostenible.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].totalpregunta}}</h3>
  
                    </div>
                  </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart9.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--Innovación-->
  
      </div>
  
      <div class="col-md-24" id="pag5">
        <!--Corresponsabilidad-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Corresponsabilidad</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.CorresponsabilidadtotalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <br>
                      <h6> <h6  class="card-subtitle  mb-25 font-weight-bolder">1. Espíritu de equipo:</h6> Alienta y fomenta el espíritu de equipo de forma permanente, impactando positivamente en el equipo y los clientes.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart10.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6> <h6 class="card-subtitle  mb-25 font-weight-bolder">2. Cooperación:</h6> Apoya el desempeño de todos los miembros del equipo y fomenta el intercambio de información y experiencias.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart11.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">3. Trabajo colaborativo:</h6> Tiene la capacidad de consolidar ideas o criterios, establecer acuerdos y comunicar de manera asertiva las decisiones construidas en equipo.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].totalpregunta}}</h3>
  
                    </div>
                  </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart12.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--Corresponsabilidad-->
      </div>
  
      <div class="col-md-24" id="pag6">
        <!--Liderazgo-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Liderazgo Transformador</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.LiderazgototalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <br>
                      <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">1. Influencia:</h6> Logra persuadir y convencer a sus interlocutores y movilizarlos a tomar decisiones o ejecutar acciones que favorezcan al cumplimiento de objetivos.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart13.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">2. Reconocimiento:</h6> Expresa satisfacción personal por los éxitos de todos los miembros del equipo, y motiva a hacer de Redeban un gran lugar para trabajar.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart14.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">3. Feedback:</h6> Propicia espacios para brindar y recibir feedback para alinear al equipo de trabajo, fomentar la confianza, definir expectativas e incrementar la productividad.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right">Puntaje ={{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].totalpregunta}}</h3>
  
                    </div>
                  </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart15.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--Liderazgo-->
      </div>
  
      <div class="col-md-24" id="pag7">
        <!--Agilidad-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Agilidad Organizacional</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.agilidadtotalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <br>
                      <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">1. Capacidad para priorizar:</h6> Identifica y prioriza las tareas claves, establece tiempos de respuesta y hace seguimiento a las acciones definidas para obtener o exceder los resultados.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div>
                      <h3 class="card-title" style="text-align: right ">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].totalpregunta}}</h3>
  
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart16.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                <div class="col-xl-6 col-6">
                  <div class="header-left">
                    <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">2. Capacidad de respuesta:</h6> Responde velozmente a los cambios guiados a otros a ver oportunidades de desarrollo y crecimiento de la transformación.</h6><br>
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                    <h6 class="font-weight-normal">B. Avanzado</h6>
                    <h6 class="font-weight-normal">C. Intermedio</h6>
                    <h6 class="font-weight-normal">D. Básico</h6>
                    <h6 class="font-weight-normal">E. No desarrollado</h6>
                  </div>
                </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right ">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].totalpregunta}}</h3>

                  </div>
                </div>

                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.Capacidad2}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.ponderadoCapacidad2}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart17.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>

                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h6><h6 class="card-subtitle  mb-25 font-weight-bolder">3. Capacidad de decisión:</h6> Analiza diferentes opciones y alternativas para tomar decisiones de manera oportuna que permitan generar una ventaja competitiva.</h6><br>
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>

                <div class="col-xl-6 col-6">
                  <div>
                    <h3 class="card-title" style="text-align: right">Puntaje = {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].totalpregunta}}</h3>

                  </div>
                </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-8 col-8">
    
                        <div class="row" id="table-contextual">
                          <div class="col-12">
                            <div class="card">
    
                              <div class="table-responsive" style="margin-top: 30px;">
                                <table class="table">
                                  <thead>
                                    <tr style="text-align: center;">
                                      <th>Evaluadores</th>
                                      <th>Muestra</th>
                                      <th>Puntaje</th>
                                      <th>Peso</th>
                                      <th>Ponderado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="table-default">
                                      <td>
                                        Autoevaluación
                                      </td>
                                      <td> 1</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Líder
                                      </td>
                                      <td> {{this.datos[0].totalLider}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Par
                                      </td>
                                      <td> {{this.datos[0].totalPar}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Colaborador
                                      </td>
                                      <td> {{this.datos[0].totalColaborador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                    <tr class="table-default">
                                      <td>
                                        Cliente
                                      </td>
                                      <td> {{this.datos[0].totalCliente}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.Capacidad3}}
                                      </td>
                                      <td>{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.PesoEvaluador}}</td>
                                      <td>
                                        {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.ponderadoCapacidad3}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    
                      </div>
    
                      <div class="col-xl-4 col-4">
                        <div id="donut-chart" #apexDonutChartRef>
                          <h4 class="card-title mb-75">Número de evaluadores</h4>
                          <apx-chart [series]="apexDonutChart18.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                            [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                            [legend]="apexDonutChart.legend"></apx-chart>
                        </div>
    
                      </div>
    
    
                    </div>
                  </div>
  
              </div>
            </div>
          </div>
        </section>
        <!--Agilidad-->
      </div>
  
      <div class="col-md-24" id="pag8">
        <!--dCargo-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">

                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="margin-bottom: 10px;">Desempeño del Cargo</h3>

                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia= {{this.totalCompetenciasEva.totalcompetencia.DCargototalCompetencia}}</h3>

                    </div>
                  </div>
  
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
  
                      <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy avanzado</h6>
                      <h6 class="font-weight-normal">B. Avanzado</h6>
                      <h6 class="font-weight-normal">C. Intermedio</h6>
                      <h6 class="font-weight-normal">D. Básico</h6>
                      <h6 class="font-weight-normal">E. No desarrollado</h6>
                    </div>
                  </div>

                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-8 col-8">
  
                      <div class="row" id="table-contextual">
                        <div class="col-12">
                          <div class="card">
  
                            <div class="table-responsive" style="margin-top: 30px;">
                              <table class="table">
                                <thead>
                                  <tr style="text-align: center;">
                                    <th>Evaluadores</th>
                                    <th>Muestra</th>
                                    <th>Puntaje</th>
                                    <th>Peso</th>
                                    <th>Ponderado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="table-default">
                                    <td>
                                      Autoevaluación
                                    </td>
                                    <td> 1</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Líder
                                    </td>
                                    <td> {{this.datos[0].totalLider}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Par
                                    </td>
                                    <td> {{this.datos[0].totalPar}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Colaborador
                                    </td>
                                    <td> {{this.datos[0].totalColaborador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                  <tr class="table-default">
                                    <td>
                                      Cliente
                                    </td>
                                    <td> {{this.datos[0].totalCliente}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.Capacidad1}}
                                    </td>
                                    <td>{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.PesoEvaluador}}</td>
                                    <td>
                                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.ponderadoCapacidad1}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <div class="col-xl-4 col-4">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart19.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
  
                    </div>
  
  
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </section>

        <!--dCargo-->
  
      </div>
  
      <div class="card-footer">
        <div class="a" *ngIf="descarga">
          <button type="button" class="btn btn-primary" (click)="pruPdf()">Descargar </button>
        </div>
        <div class="b" *ngIf="descargado">
          <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Descargando...
        </button>
      </div>
        
  
      </div>

    </div>

  </div>
</div>