<div class="content-wrapper container-xxl p-0" >
    <div class="content-body" id="htmlData">
      <!-- Content-Header Component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <div class="col-sm-12 col-md-24 d-flex align-items-center justify-content-center" *ngIf="cargandoPlan">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="cargaPlan">
        <div class="col-md-24" id="pag1">
          <!--Datos funcionario-->
          <section >
           <div class="row">
               <div class="col-12">
                   <div class="card">
                     <div class="card-header" style="justify-content: center;">
                         <div class="row">
                           <div >
                             <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">PLAN DE DESARROLLO INDIVIDUAL</span><br>
                             <h6 class="font-weight-bolder" style="margin-left: 25%;" >Datos del Colaborador</h6>
                           </div>                     
                         </div>
                     </div>
                     <div class="card-body">
                         <div class="row">
                             <div class="col-xl-6 col-6">
                               <div class="table-responsive" >
                                 <table class="table" >
                                   <tbody >
                                     <tr> 
                                       <th style="border: 0;" >Nombre del Colaborador</th>
                                       <th style="border: 0;" > <h6>{{plan.nombre}}</h6>  </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cédula</th>
                                       <th style="border: 0;" > <h6>{{plan.idColaborador}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cargo del Colaborador</th>
                                       <th style="border: 0;" > <h6>{{plan.cargoColaborador}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Área</th>
                                       <th style="border: 0;" > <h6>{{plan.areaColaborador}}</h6> </th>
                                     </tr>
           
                                   </tbody>
                                 </table>
                               </div>
   
                             </div>
                             <div class="col-xl-6 col-6">
                               <div class="table-responsive" >
                                 <table class="table" >
                                   <tbody >
                                     <tr> 
                                       <th style="border: 0;" >Nombre del Líder</th>
                                       <th style="border: 0;" > <h6>{{plan.nombreJefe}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cargo del Líder</th>
                                       <th style="border: 0;" > <h6>{{plan.cargoJefe}}</h6>  </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Período</th>
                                       <th style="border: 0;" ><h6>2021</h6> </th>
                                     </tr>
           
                                   </tbody>
                                 </table>
                               </div>
                             </div>
                         </div>
                     </div>
                   </div>
               </div>
           </div>
           <!-- Tabla 1-->
          <section id="bar-and-hori-bar-charts">
           <div class="row">      
             <div class="col-xl-12 col-24">
               <div class="card">
                 <div class="row" id="table-contextual">
                   <div class="col-12">
                     <div class="card">                    
                       <div class="card-body" > 
                         <div class="table-responsive" style="margin-top: 30px;">
                           <table class="table">
                             <thead>
                               <tr style="text-align: center;">
                                <th>Competencia</th>
                                 <th>Tipo de actividad</th>

                                 <th>Descripción</th>
                                 <th>Fecha inicio</th>
                                 <th>Costo</th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr class="table-default" *ngFor="let dato of datos">
                                <td> {{dato.COMPETENCIA}}</td>
                                 <td>
                                   {{dato.TIPODEACTIVIDAD}}
                                 </td>

                                 <td>{{dato.DESCRIPCION}} </td>
                                 <td>{{dato.FECHA}}</td>
                                 <td > ${{dato.COSTO}}  </td>
                                 
                               </tr>
     
                             </tbody>
                           </table>
                         </div>
                       </div>           
                     </div>
                   </div>
                 </div>
    
               </div>
             </div>
             <!--/ Tabla 1 End -->
           </div>
            </section>
            <!-- Bar Chart Start -->
          </section>
          <!--Datos funcionario End-->
   
   
         </div>

      </div>
    </div>
  </div>

