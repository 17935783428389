import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { colors } from 'app/colors.const';
import { GeneralResponse } from 'app/main/models/generalResponse';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { ResultadoArea } from '../../../models/ResultArea';
import { ReporteIndiService } from '../reporte-indi/reporte-indi.service';
import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}
@Component({
  selector: 'app-ver-reportearea',
  templateUrl: './ver-reportearea.component.html',
  styleUrls: ['./ver-reportearea.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VerReporteareaComponent implements OnInit {
  public generalResponse:GeneralResponse;
  public contentHeader: object;
  area!: { nomArea: String };
  areaActual: String;
  ResultadosArea: ResultadoArea;
  prueba: number;
  @ViewChild('apexRadarChartRef') apexRadarChartRef: any;

  private successColorShade = '#ee3b3b ';
  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private labelColor = '#6e6b7b';
  private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout

  public barChart = {
    chartType: 'bar',
    datasets: [
      {
        label: 'Competencia',
        data: [],
        backgroundColor: [
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
          'rgba( 15, 45, 131 )',
        ],
        borderColor: 'transparent',
        hoverBackgroundColor: 'rgba( 15, 45, 131 )',
        hoverBorderColor: this.successColorShade
      },
      {
        label: 'Promedio',
        data: [],
        backgroundColor: [
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
          'rgba(  241, 158, 46  )',
        ],
        borderColor: 'transparent',
        hoverBackgroundColor: 'rgba(  241, 158, 46  )',
        hoverBorderColor: this.successColorShade
      }
    ],
    labels: ['Corresponsabilidad', 'Liderazgo Transformador', 'Innovación', 'Desempeño del cargo', 'Agilidad Organizacional', 'Flexibilidad al cambio', 'Desempeño Superior'],
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: true
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      },
      scales: {
        xAxes: [
          {
            barThickness: 15,
            display: true,
            gridLines: {
              display: true,
              color: this.grid_line_color,
              zeroLineColor: this.grid_line_color
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              fontColor: this.labelColor
            }
          }
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: this.grid_line_color,
              zeroLineColor: this.grid_line_color
            },
            ticks: {
              stepSize: 1,
              min: 0,
              max: 5,
              fontColor: this.labelColor
            }
          }
        ]
      }
    },
    legend: true
  };

  public plugins = [
    {
      beforeInit(chart) {
        chart.legend.afterFit = function () {
          this.height += 20;
        };
      }
    }
  ];

  constructor(private service: ReporteIndiService, private rutaActiva: ActivatedRoute, private alert:SweetAlertService) { }

  ngOnInit() {
    this.area = {
      nomArea: this.rutaActiva.snapshot.params.nomArea
    };
    this.rutaActiva.params.subscribe(
    (params: Params) => {
      this.area.nomArea = params.nomArea;
      this.areaActual = String(params.nomArea);
    });
    this.obtenerResultados();
  }


  obtenerResultados() {
    this.service.resultadosAreas(this.areaActual).subscribe(data => {
      this.generalResponse = data as GeneralResponse;

      //Competecia
      this.barChart.datasets[0].data[0] = this.generalResponse.payload['resultadosArea']['corresponsabilidad'];
      this.barChart.datasets[0].data[1] = this.generalResponse.payload['resultadosArea']['liderazgo']; 
      this.barChart.datasets[0].data[2] = this.generalResponse.payload['resultadosArea']['innovacion'];
      this.barChart.datasets[0].data[3] = this.generalResponse.payload['resultadosArea']['DesempenoCargo']; 
      this.barChart.datasets[0].data[4] = this.generalResponse.payload['resultadosArea']['agilidad'];
      this.barChart.datasets[0].data[5] = this.generalResponse.payload['resultadosArea']['flexibilidad'];
      this.barChart.datasets[0].data[6] = this.generalResponse.payload['resultadosArea']['DesempenoSuperior']; 

      //Promedio
      this.barChart.datasets[1].data[0] = this.generalResponse.payload['resultadosCompetencias']['corresponsabilidad'];
      this.barChart.datasets[1].data[1] = this.generalResponse.payload['resultadosCompetencias']['liderazgo']; 
      this.barChart.datasets[1].data[2] = this.generalResponse.payload['resultadosCompetencias']['innovacion'];
      this.barChart.datasets[1].data[3] = this.generalResponse.payload['resultadosCompetencias']['DesempenoCargo']; 
      this.barChart.datasets[1].data[4] = this.generalResponse.payload['resultadosCompetencias']['agilidad'];
      this.barChart.datasets[1].data[5] = this.generalResponse.payload['resultadosCompetencias']['flexibilidad'];
      this.barChart.datasets[1].data[6] = this.generalResponse.payload['resultadosCompetencias']['DesempenoSuperior'];
      this.obtenerResultados(); 
    }, err => {
      console.error(err);
      this.alert.alertNotification('error', 'Error al obtener el reporte', 5000);
    });
  }
}
