<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <form #loginForm="ngForm">
          <section class="invoice-list-wrapper">
            <div>
                <h4>Evaluados</h4>
            </div>
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Cantidad de evaluadores  </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div  class="containerCantidad">
                        <div class="row">
                          <div class="col-sm" style="margin-bottom: 10px;">
                            <h5>Mínimo</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantMini (onChange)="minOnChange($event)" >
                                <div class="btn-group touchspin-wrapper">
                                  <input type="text"formControlName="valorMinCantEvaluadores" >
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" [disable]="true" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" formControlName="valorMinCantEvaluadores" type="text">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" [disable]="true" ></button>

                                </div>
                              </core-touchspin>
                            </div>   

                          </div>
                          <div class="col-sm">
                            <h5>Máximo</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantMax (onChange)="maxOnChange($event)"  >
                                <div class="btn-group touchspin-wrapper">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" type="number">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" ></button>

                                </div>
                              </core-touchspin>
                            </div>  
                          </div>
                        </div>
                      </div>
                  </ng-template>
                </ngb-panel>
                <hr>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Peso de evaluadores </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="containerCantidad">
                        <div class="row">
                          <div class="col-sm" style="margin-bottom: 10px;">
                            <h5>Líderes</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantJefe (onChange)="jefeOnChange($event)" >
                                <div class="btn-group touchspin-wrapper">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" type="number">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" ></button>

                                </div>
                              </core-touchspin>
                            </div>   
                          </div>
                          <div class="col-sm"  style="margin-bottom: 10px;" >
                            <h5>Pares</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantPares (onChange)="paresOnChange($event)"  >
                                <div class="btn-group touchspin-wrapper">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" type="number">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" ></button>

                                </div>
                              </core-touchspin>
                            </div>  
                          </div>
                          <div class="col-sm"  style="margin-bottom: 10px;" >
                            <h5>Colaborador</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantSub (onChange)="subOnChange($event)"  >
                                <div class="btn-group touchspin-wrapper">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" type="number">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" ></button>

                                </div>
                              </core-touchspin>
                            </div>  
                          </div>
                          <div class="col-sm"  style="margin-bottom: 10px;" >
                            <h5>Clientes</h5>
                            <div class="containerMinMax">
                              <core-touchspin [numberValue]=cantClient (onChange)="clientOnChange($event)"  >
                                <div class="btn-group touchspin-wrapper">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-down" ></button>
                                  <input class="form-control ng-untouched ng-pristine ng-valid" type="number">
                                  <button class="btn btn-sm btn-primary btn-touchspin btn-touchspin-up" ></button>

                                </div>
                              </core-touchspin>
                            </div>  
                          </div>
                        </div>
                      </div>
                    
                  </ng-template>
                </ngb-panel>
                
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Fechas de las etapas </span>
                  </ng-template>
                  <ng-template ngbPanelContent >
                    
                    <div class="containerCantidad">
                      <div class="row">
                        <div class="col-sm" style="margin-bottom: 10px;">
                          <h5 style="margin-bottom: 20px;" >Etapa 1 - Postulación</h5>
                          <div class="containerMinMax">
                            <label style="margin-right: 15px;" for="date-Inicio1">Fecha Inicio</label>
                            <input style="margin-right: 15px;" class="form-control" [formControl]="fechaIni1Control1" type="date" id="date-Inicio1" required/>
                            <label style="margin-right: 15px;" for="date-Fin1">Fecha Fin</label>
                            <input class="form-control" [formControl]="fechaFin1Control1" type="date" id="date-Fin1" required />
                            
                          </div>
                          
                          <h5 style="margin-bottom: 20px; margin-top: 20px;" >Etapa 2 - Evaluación</h5>
                          <div class="containerMinMax">
                            <label style="margin-right: 15px;" for="date-Inicio2">Fecha Inicio</label>
                            <input style="margin-right: 15px;" class="form-control" [formControl]="fechaIni2Control2" type="date" id="date-Inicio2" required />
                            <label style="margin-right: 15px;" for="date-Fin2">Fecha Fin</label>
                            <input class="form-control" [formControl]="fechaFin2Control2" type="date" id="date-Fin2" required />
                            
                          </div>
                        </div>
                        <div class="col-sm" style="margin-bottom: 10px;">
                          <h5 style="margin-bottom: 20px;" >Etapa 3 - Reportes</h5>
                          <div class="containerMinMax">
                            <label style="margin-right: 15px;" for="date-Inicio3">Fecha Inicio</label>
                            <input style="margin-right: 15px;" class="form-control" [formControl]="fechaIni3Control3" type="date" id="date-Inicio3" required />
                            <label style="margin-right: 15px;" for="date-Fin3">Fecha Fin</label>
                            <input class="form-control" [formControl]="fechaFin3Control3" type="date" id="date-Fin3" required />
                            
                          </div>  
                        </div>
                      </div>
                    </div>
                    
                  </ng-template>
                </ngb-panel>

                
              </ngb-accordion>

              <div class="containerBoton">

                <button type="submit" [disabled]="loginForm.form.invalid" type="button" class="btn btn-primary" (click)="validacionGuardar()"  >Guardar</button>

              </div>
          </section>



        </form>

    </div>    
</div>    

              