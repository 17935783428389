/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_r9geC4oiZ",
  //"aws_cognito_identity_pool_id": "us-east-2:12ad18f4-b05b-4f4f-86f1-7ddfa1f6be5c",
  "aws_user_pools_web_client_id": "44ao7qgu4l0fnf87knrs4tjrb4",
  "oauth": {
      domain: 'evaluacion360.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://sandbox.evaluacion360.redebandigital.com',
      redirectSignOut: 'https://sandbox.evaluacion360.redebandigital.com',
      responseType: 'code',
      options: {
        AdvanceSecurityDataCollectionFlag: false
      }
  }
};

const provider = 'AzureAD';
export  {awsmobile, provider};
