import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { colors } from 'app/colors.const';
import { ResultadoArea } from '../../../models/ResultArea';
import { ReporteIndiService } from '../reporte-indi/reporte-indi.service';

@Component({
  selector: 'app-ver-reportecargo',
  templateUrl: './ver-reportecargo.component.html',
  styleUrls: ['./ver-reportecargo.component.scss']
})
export class VerReportecargoComponent implements OnInit {
  public contentHeader: object;
  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private grid_line_color = 'rgba(200, 200, 200, 0.2)'; 
  cargoActual: String;
  ResultadosArea: ResultadoArea
  cargo!:{nomCargo:String};

  public DateRangeOptions = {
    altInput: true,
    mode: 'range',
    altInputClass: 'form-control flat-picker bg-transparent border-0 shadow-none flatpickr-input',
    defaultDate: ['2019-05-01', '2019-05-10'],
    altFormat: 'Y-n-j'
  };

  public horiBarChart = {
    chartType: 'horizontalBar',
 
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'right'
        }
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              zeroLineColor: this.grid_line_color,
              borderColor: 'transparent',
              color: this.grid_line_color,
              drawTicks: false
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              min: 0
            }
          }
        ],
        yAxes: [
          {
            display: true,
            barThickness: 15,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true
            }
          }
        ]
      }
    },
 
    labels: ['Corresponsabilidad', 'Liderazgo Transformador', 
    'Innovación ', 'Desempeño del cargo', 'Agilidad Organizacional',
     'Flexibilidad al cambio', 'Desempeño Superior'],
    datasets: [
      {
        data: [1,1,1,1,1,1,1],
        backgroundColor: 'rgba( 15, 45, 131 )',
        borderColor: 'transparent',
        hoverBackgroundColor: 'rgba( 15, 45, 131 )',
        hoverBorderColor: 'rgba( 15, 45, 131 )',
        borderWidth: 42,
        radius: 12,
        pointRadius: 12,
        hoverBorderWidth: 12
      }
    ],
 
    legend: false
  };

  obtenerResultados(){
    this.service.resultadosCargo(this.cargoActual).toPromise().then((result:  ResultadoArea)=>{
    this.ResultadosArea=result;
    this.horiBarChart = {
        chartType: 'horizontalBar',

    datasets: [
      {
        data: [this.ResultadosArea.datos.corresponsabilidad, this.ResultadosArea.datos.liderazgo, this.ResultadosArea.datos.innovacion, this.ResultadosArea.datos.DesempenoCargo, 
          this.ResultadosArea.datos.agilidad, this.ResultadosArea.datos.flexibilidad, this.ResultadosArea.datos.DesempenoSuperior ],
        backgroundColor: 'rgba( 15, 45, 131 )',
        borderColor: 'transparent',
        hoverBackgroundColor: 'rgba( 15, 45, 131 )',
        hoverBorderColor: 'rgba( 15, 45, 131 )',
        borderWidth: 42,
        radius: 12,
        pointRadius: 12,
        hoverBorderWidth: 12
      }
    ],
    labels: ['Corresponsabilidad', 'Liderazgo Transformador', 
    'Innovación ', 'Desempeño del cargo', 'Agilidad Organizacional',
     'Flexibilidad al cambio', 'Desempeño Superior'],
 
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'right'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              zeroLineColor: this.grid_line_color,
              borderColor: 'transparent',
              color: this.grid_line_color,
              drawTicks: false
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              min: 0
            }
          }
        ],
        yAxes: [
          {
            display: true,
            barThickness: 15,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true
            }
          }
        ]
      }
    },     
 
    legend: true
    }
      
    })
    
  }
 

  constructor(private service: ReporteIndiService,private rutaActiva: ActivatedRoute) { }

  async ngOnInit(){
    this.cargo = {
      nomCargo: this.rutaActiva.snapshot.params.nomCargo
    }; 
    this.rutaActiva.params.subscribe(
      (params: Params)=>{
        this.cargo.nomCargo = params.nomCargo;
        this.cargoActual = String(params.nomCargo);
  
      });

    await this.obtenerResultados() 
  }

}
