<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="invoice-list-wrapper">
            <div class="row justify-content-center mb-1">
                <div class="col-sm-9 col-md-11 p-2" style="background-color: #fff; border-radius: 6px;">
                    <div class="row">
                        <div class="col-sm-12 col-md-7 justify-content-center d-flex align-items-center">
                            <h3>Porcentaje de colaboradores que han realizado la evaluación de desempeño</h3>
                        </div>
                        <div class="col-sm-12 col-md-5">
                            <div class="card  mb-0 pb-0">
                                <div class="card-body p-0">
                                  <div id="goalChartoptions" class="mb-1 mt-1 pt-1" #goalChartRef>
                                    <!-- Apexchart component-->
                                    <apx-chart
                                      [chart]="{
                                        height: 245,
                                        width: isMenuToggled === false ? goalChartRef.offsetWidth : goalChartoptions.chart.width,
                                        type: 'radialBar',
                                        sparkline: {
                                          enabled: true
                                        },
                                        dropShadow: {
                                          enabled: true,
                                          blur: 3,
                                          left: 1,
                                          top: 1,
                                          opacity: 0.1
                                        }
                                      }"
                                      [colors]="goalChartoptions.colors"
                                      [plotOptions]="goalChartoptions.plotOptions"
                                      [fill]="goalChartoptions.fill"
                                      [series]="data.goalOverview.series"
                                      [stroke]="goalChartoptions.stroke"
                                      [grid]="goalChartoptions.grid"
                                    ></apx-chart>
                                    <!-- / Apexchart component-->
                                  </div>
                                  <div class="row border-top text-center mx-0">
                                    <div class="col-6 border-right py-1">
                                      <p class="card-text text-muted mb-0">Finalizados</p>
                                      <h3 class="font-weight-bolder mb-0" *ngIf="!isLoadingPage">{{evaluacionesFinalizadas}}</h3>
                                      <h3 class="font-weight-bolder mb-0" *ngIf="isLoadingPage">
                                          <div class="spinner-border mr-50" role="status">
                                              <span class="sr-only">Loading...</span>
                                          </div>
                                      </h3>
                                    </div>
                                    <div class="col-6 py-1">
                                        <p class="card-text text-muted mb-0">Pendientes</p>
                                        <h3 class="font-weight-bolder mb-0" *ngIf="!isLoadingPage">{{evaluacionesPendientes}}</h3>
                                        <h3 class="font-weight-bolder mb-0" *ngIf="isLoadingPage">
                                            <div class="spinner-border mr-50" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </h3>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="my-2">Colaboradores</h2>
            <div class="card">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <label class="d-flex align-items-center">Ver&nbsp;
                                    <select class="form-control mx-20" [(ngModel)]="selectedOption" (ngModelChange)="changePageSize($event)">
                                        <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 col-12 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="row pb-md-0 align-items-center w-100">
                            <div class="col-sm-12 col-md-4 pr-0">
                                <button type="button" disabled title="No hay registros para la descarga." (click)="showMessageNotRows()" class="btn btn-primary form-control" rippleEffect *ngIf="rows.length == 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button type="button" class="btn btn-primary form-control" rippleEffect (click)="crearNovedadDescargar()" *ngIf="!creandoNovedad && rows.length > 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button class="btn btn-primary form-control" type="button" rippleEffect *ngIf="creandoNovedad && rows.length > 0">
                                    <span class="ml-25 align-middle">Descargando</span>
                                    <span class="spinner-border spinner-border-sm" role="status" style="margin-left: 5px;" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="col-sm-12 col-md-4 pr-0">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control" placeholder="Búsqueda" (keyup)="filterAll()"/>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-4 ml-md-0 my-sm-1 ml-sm-1 my-1 ml-1 mr-0 pr-0 pr-sm-1">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-1 mr-0 pr-0">
                                    <ng-select
                                      [items]="selectStatus"
                                      [(ngModel)]="selectedStatus"
                                      bindLabel="name"
                                      placeholder="Seleccione estado"
                                      (change)="filterAll()"
                                    ></ng-select>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <ngx-datatable #table [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [limit]="selectedOption"
                [columnMode]="ColumnMode.force" [headerHeight]="120" [footerHeight]="50" [scrollbarH]="false" [messages]="{emptyMessage: ''}" >
                    <ngx-datatable-column name="Colaborador" prop="nombre" [width]="440">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="d-flex">
                                <div class="avatar mr-75">
                                    <img src="{{urlImage}}{{row.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                        class="" width="42" height="42" data-placement="left"/>
                                </div>
                                <div class="columnNombre">
                                    <a  href="javascript:void(0)" >{{row?.nombre}}</a>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Jefe" prop="nombreJefe1" [width]="420">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="columnJefe">
                                {{row?.nombreJefe1}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>                    

                    <ngx-datatable-column name="Estado" prop="estadoEtapa2" [width]="200">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="badge badge-glow" [ngClass]="{'badge-success': row.estadoEtapa2 == 'Finalizado', 'badge-danger': row.estadoEtapa2 == 'Pendiente', 'badge-warning': row.estadoEtapa2 == 'En curso'}">
                                {{row?.estadoEtapa2}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Pendientes" prop="totalEvaluados" [width]="180">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                              {{row?.totalEvaluados - row?.evaluacionesFinalizadas}}
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                          <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                            Total de registros {{rowCount}}
                          </div>
                          <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                            No hay registros ...
                          </div>
                          <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                            Cargando información...
                          </div>
                          <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage" [size]="pageSize"
                            (change)="onChange($event)" [hidden]="!((rowCount / pageSize) > 1)">
                          </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>