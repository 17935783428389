<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- Content-Header Component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <!--Datos funcionario-->
      <section>
          <div class="row">
              <div class="col-12">
                  <div class="card">
                    <div class="card-header" style="justify-content: center;">
                        <div class="row">
                          <div >
                            <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">Reporte del Cargo {{cargoActual}}</span><br>
                            
                          </div>                     
                        </div>
                        
                    </div>
                
                    <div class="card">
                          <div
                            class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column"
                          >


                          </div>
                          <div class="card-body">
                            <canvas
                              baseChart
                              class="horizontal-bar-chart-ex chartjs"
                              height="400"
                              [datasets]="horiBarChart.datasets"
                              [labels]="horiBarChart.labels"
                              [options]="horiBarChart.options"
                              [legend]="horiBarChart.legend"
                              [chartType]="horiBarChart.chartType"
                            ></canvas>
                          </div>
                        </div>
                    </div>       
              </div>
          </div>
      </section>
      <!--Datos funcionario End-->

    </div>
  </div>
