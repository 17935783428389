import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigGeneralServices } from 'app/main/dashboard/etapa1/configuracion/configgeneral.service';
import { requestEvaluados,DatosEva } from 'app/main/models/requestEvaluados';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import { RequestDesaprobar } from 'app/main/models/requestDesaprobar';
import { ListEvaluadoresService } from './list-evaluadores.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-list-evaluadores',
  templateUrl: './list-evaluadores.component.html',
  styleUrls: ['./list-evaluadores.component.scss']
})
export class ListEvaluadoresComponent implements OnInit {
  urlImage = environment.urlImages;
  contentHeader: object;
  public ColumnMode = ColumnMode;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  searchValue = '';
  public selectStatus: any = [
    { name: 'Aprobado', value: 'Aprobado' },
    { name: 'Rechazado', value: 'Rechazado' }
  ];

  selectedStatus = null;
  tempData = [];
  rows:requestEvaluados[] = [];
  formBuscar:FormGroup;
  isLoadingPage = false;
  evaluado:requestEvaluados;
  datos: Array<DatosEva>[];
  status: String = "Aprobado"
  evaluadoDesaprobado: requestEvaluados;
  attributes360:any;
  formDesaprobar:FormGroup;
  
  urlEvaluacion: string;
  idEvaluado: number;
  nombreEvaluado: String;
  relacionTrabajo: String;
  motivo: String;
  idEvaluador: number ;
  idEvaluadoasd: number = 1234567891;

  evaluacion!: {idUsuario: number};

  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private formBuilder:FormBuilder,private router:Router,private rutaActiva: ActivatedRoute,  private ListService:ListEvaluadoresService) { }

  ngOnInit() {
    

    //params
    this.evaluacion = {
      idUsuario: this.rutaActiva.snapshot.params.idUsuario,

    };  
    this.rutaActiva.params.subscribe(
      (params: Params)=>{
        this.evaluacion.idUsuario = params.idUsuario;
        this.idEvaluador = Number(params.idUsuario);

      }
    );

    this.getContentHeader();
    this.obtenerEvaluadores();

    console.log(this.idEvaluador)

    this.formBuscar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })

  }

  getContentHeader() {
    this.contentHeader = {
      headerTitle: 'Monitoreo etapa 1',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Monitoreo',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombreEvaluador && d.nombreEvaluador.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : null;
    if (filterStatus != null) filterStatus =  filterStatus;

    filterAllData = filterAllData.filter(row => {
      return (row.aprobacion === filterStatus) || filterStatus == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }

  obtenerEvaluadores(){
    this.isLoadingPage = true;
    this.ListService.getEvaluadosPorIdUsuario(this.idEvaluador).subscribe(data => {
      if(data != null){
        this.rows = data['datos'] as requestEvaluados[];
        this.tempData = this.rows;
        this.isLoadingPage = false;
        console.log(this.rows)
      }
    }, err =>{
      console.log(err);
      this.isLoadingPage = false;
    })
    
  }

}
