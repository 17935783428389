<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Content-Header Component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <!--Datos funcionario-->
    <section>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header" style="justify-content: center;">
              <div class="row">
                <div>
                  <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">Reporte del Área
                    {{areaActual}}</span><br>
                </div>
              </div>
            </div>
            <div class="card">
              <div
                class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
              </div>
              <div class="card-body">
                  <div id="chartjs-bar-chart">
                    <canvas id="barChartCanva" baseChart #barChartRef height="400" [datasets]="barChart.datasets"
                      [labels]="barChart.labels" [options]="barChart.options" [legend]="barChart.legend"
                      [chartType]="barChart.chartType" [plugins]="plugins">
                    </canvas>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Datos funcionario End-->
  </div>
</div>