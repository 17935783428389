
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6">
            <h1 class="mt-2 mb-4 text-center">Añadir Actividad para plan de desarrollo</h1>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-12">
                          <div class="table-responsive" >
                            <table class="table" >
                              <tbody >
                                <tr> 
                                  <th style="border: 0;" >Nombre del Colaborador</th>
                                  <th style="border: 0;" > 
                                    <div class="media d-flex align-items-center">
                                    <div class="avatar mr-75">
                                        <img src="{{urlImage}}{{id}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                            class="" width="42" height="42" data-placement="left"/>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h6 class="mb-0">{{nombre}}</h6>
                                    </div>
                                    </div> 
                                  </th>
                                </tr>
                                <tr> 
                                  <th style="border: 0;" >Competencia</th>
                                  <th style="border: 0;" > 
                                    <div class="d-flex mb-2">
                                    
                                        <select id="competencia" class="form-control form-control" (change)="selectcompe($event)">
                                            <option selected value="0">Competencias</option>
                                            <option *ngFor="let dato of datos2">{{dato.competencia}}</option>
                                          </select>
                                    
                                    </div> 
                                   </th>
                                </tr>
                                <tr> 
                                  <th style="border: 0;" >Tipo de actividad</th>
                                  <th style="border: 0;" > 
                                    <div class="d-flex mb-2">
                                    
                                        <select class="form-control form-control" (change)="selectacti($event)">
                                            <option  selected value="0" selected>Selecciona una opción</option>
                                            <option  *ngFor="let dato of datos">{{dato.nombreActividad}}</option>
                                        </select>
                                    
                                    </div>
                                  </th>
                                </tr>
                                <tr> 
                                  <th style="border: 0;" >Descripción</th>
                                  <th style="border: 0;" > 
                                    <form >
                                    <fieldset class="form-group">
                                        <textarea class="descripcion form-control" (change)="selectdescri($event)" formControlName="descripcion" id="textAreadescripcion" rows="3"
                                            placeholder="Agrega una descripción"
                                            ></textarea>             
                                    </fieldset>
                                    </form> 
                                  </th>
                                </tr>
                                <tr> 
                                  <th style="border: 0;" >Fecha de inicio</th>
                                  <th style="border: 0;" > 
                                    <form [formGroup]="form">
                                      
                                      <input class="date form-control" [min]="dateini" (change)="selectfecha($event)"  formControlName="date" id="date" type="date" />
                                    </form>
  
                                    </th>
                                </tr>
                              <tr> 
                                <tr> 
                                    <th style="border: 0;" >Costo</th>
                                    <th style="border: 0;" > 
                                    <form [formGroup]="form">
                                      
                                      <fieldset class="form-group">

                                              <input class="costo form-control" (change)="selectcosto($event)" placeholder="Agrega un costo (Solo números)" formControlName="number" id="number" type="text">
                                      
                                      </fieldset>
                                    </form>
                                         <div *ngIf="f.number.touched && f.number.invalid" >
                                           <div *ngIf="f.number.errors.pattern">Ingresa solo números.</div>
                                        
                                         </div> 
                                    </th>
                                </tr>
      
                              </tbody>
                            </table>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-danger" (click)="cancelar()">
                    Cancelar
                </button>
                <button [disabled]="!form.valid" class="btn btn-success mx-1" (click)="enviarActividad()" >Guardar</button>

            </div>          
        </div>
    </div>
