import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ChartsModule } from 'ng2-charts';

import { EvaluacionDesempenoComponent } from './etapa2/evaluacion-desempeno/evaluacion-desempeno.component';
import { RegistroEvaluadoresComponent } from './etapa1/registro-evaluadores/registro-evaluadores/registro-evaluadores.component';
import { RevisarPostulacionesComponent } from './etapa1/revisar-postulaciones/revisar-postulaciones/revisar-postulaciones.component';
import { ReporteComponent } from './etapa3/reporte/reporte.component';
import { AgregarEvaluadorComponent } from './etapa1/registro-evaluadores/agregar-evaluador/agregar-evaluador.component';
import { VisualizarEvaluadosComponent } from './etapa2/evaluacion-desempeno/visualizar-evaluados/visualizar-evaluados.component';
import { HttpClientModule } from '@angular/common/http';
import { EvaluacionDesempenoServices} from '../dashboard/etapa2/evaluacion-desempeno/evaluacion-desempeno.service';
import { AuthGuard } from 'app/auth/helpers';
import { VisualizarEvaluadosServices } from './etapa2/evaluacion-desempeno/visualizar-evaluados/visualizar-evaluados.service';
import { MonitoreoComponent } from './etapa1/monitoreo/monitoreo.component';
import { ConfiggeneralComponent } from './etapa1/configuracion/configgeneral/configgeneral.component';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { MonitoreoEtapa2Component } from './etapa2/monitoreo-etapa2/monitoreo-etapa2.component';
import { EvaluacionesComponent } from './etapa1/evaluaciones/evaluaciones.component';
import { ListEvaluadoresComponent } from './etapa1/list-evaluadores/list-evaluadores.component';
import { ReporteIndiComponent } from './etapa3/reporte-indi/reporte-indi.component';
import { VerReporteindiComponent } from './etapa3/ver-reporteindi/ver-reporteindi.component';
import { ReporteAreaComponent } from './etapa3/reporte-area/reporte-area.component';
import { VerReporteareaComponent } from './etapa3/ver-reportearea/ver-reportearea.component';
import { ReporteCargoComponent } from './etapa3/reporte-cargo/reporte-cargo.component';
import { VerReportecargoComponent } from './etapa3/ver-reportecargo/ver-reportecargo.component';
import { VerReportecompeComponent } from './etapa3/ver-reportecompe/ver-reportecompe.component';
import { PlandedesaComponent } from './etapa4/plandedesa/plandedesa.component';
import { VerPlandesaComponent } from './etapa4/ver-plandesa/ver-plandesa.component';
import { AddplandesaComponent } from './etapa4/addplandesa/addplandesa.component';
import { MiplanComponent } from './etapa4/miplan/miplan.component';
import { ColaboradoresComponent } from './etapa1/colaboradores/colaboradores.component';
import { ResultadosComponent } from './etapa3/resultados/resultados.component';
import { MonitoreoEtapa3Component } from './etapa3/monitoreo-etapa3/monitoreo-etapa3.component';

const routes = [
  {
    path: 'configgeneral',
    component: ConfiggeneralComponent,
    data: {roles: ['Administradores']},
    canActivate: [AuthGuard]
  },
  {
    path: 'novedades',
    component: ColaboradoresComponent,
    data: {roles: ['Administradores', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluadores',
    component: RegistroEvaluadoresComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluadores/agregar',
    component: AgregarEvaluadorComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'postulaciones',
    component: RevisarPostulacionesComponent,
    data: {roles: ['Administradores', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'monitoreo-etapa-1',
    component: MonitoreoComponent,
    data: {roles: ['Administradores']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluaciones',
    component: EvaluacionesComponent,
    data: {roles: ['Administradores']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluacion',
    component: VisualizarEvaluadosComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluacion/360/:ID_evaluado/:ID_evaluador/:nombreEvaluado/:competenciap',
    component: EvaluacionDesempenoComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'reporteIndividual/:idUsuario',
    component: VerReporteindiComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'reporteArea',
    component: ReporteAreaComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'reporteCargo',
    component: ReporteCargoComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'evaluadoresUsuario/:idUsuario',
    component: ListEvaluadoresComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'monitoreo-etapa-2',
    component: MonitoreoEtapa2Component,
    data: {roles: ['Administradores']},
    canActivate: [AuthGuard]
  },
  {
    path: 'miReporte',
    component: ReporteComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'verreportearea/:nomArea',
    component: VerReporteareaComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'verreportecargo/:nomCargo',
    component: VerReportecargoComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'verreportecompe',
    component: VerReportecompeComponent,
    data: {roles: ['Administradores', 'us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'reporteindi',
    component: ReporteIndiComponent,
    data: {roles: ['Administradores','us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'plandesarrollo',
    component: PlandedesaComponent,
    data: {roles: ['Administradores','us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'verplandesarrollo/:idUsuario',
    component: VerPlandesaComponent,
    data: {roles: ['Administradores','us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'addplandesarrollo/:idColaborador/:nombre',
    component: AddplandesaComponent,
    data: {roles: ['Administradores','us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'miplandesarrollo',
    component: MiplanComponent,
    data: {roles: ['Administradores','us-east-1_r9geC4oiZ_AzureAD', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'resultados',
    component: ResultadosComponent,
    data: {roles: ['Administradores', 'Jefes']},
    canActivate: [AuthGuard]
  },
  {
    path: 'monitoreo-etapa-3',
    component: MonitoreoEtapa3Component,
    data: {roles: ['Administradores']},
    canActivate: [AuthGuard]
  },
  
];

@NgModule({
  declarations: [RegistroEvaluadoresComponent, RevisarPostulacionesComponent, EvaluacionDesempenoComponent, ReporteComponent, AgregarEvaluadorComponent, VisualizarEvaluadosComponent, MonitoreoComponent, ConfiggeneralComponent, MonitoreoEtapa2Component, EvaluacionesComponent, ListEvaluadoresComponent, ReporteIndiComponent, VerReporteindiComponent, ReporteAreaComponent, VerReporteareaComponent, ReporteCargoComponent, VerReportecargoComponent, VerReportecompeComponent, PlandedesaComponent, VerPlandesaComponent, AddplandesaComponent, MiplanComponent, ColaboradoresComponent, ResultadosComponent, MonitoreoEtapa3Component],
  imports: [
    BrowserModule,
    FileUploadModule,
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgbModule,
    CoreTouchspinModule,
    Ng2FlatpickrModule,
    ChartsModule,
    NgSelectModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgApexchartsModule,
    NgxDatatableModule
  ],
  providers: [HttpClientModule,EvaluacionDesempenoServices,VisualizarEvaluadosServices],
  exports: []
})
export class DashboardModule {}
