import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  private urlNovedades:string = environment.apiNovedades;

  constructor(private http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

  /**
   * Obtiene las novedades
   */
  getNovedades(idColaborador:number){
    let params = new HttpParams();
    params = params.append('idColaborador', idColaborador);
    // params = params.append('proceso', tipoProceso);
    return this.http.get(this.urlNovedades, {params: params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Crea la novedad
   * @param request 
   * @returns 
   */
  sendNovedad(request:any){
    return this.http.post(this.urlNovedades, request, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
