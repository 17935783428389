import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VerplanserviceService} from '../verplanservice.service';
import { Plan, DatosObj } from 'app/main/models/planDesarrollo';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Actividades, DatosAct,DatosCom } from 'app/main/models/planDesarrollo';
import moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-ver-plandesa',
  templateUrl: './ver-plandesa.component.html',
  styleUrls: ['./ver-plandesa.component.scss']
})
export class VerPlandesaComponent implements OnInit {

  public contentHeader: object;
  actividades: Actividades
  idColaborador : number ;
  urlImage = environment.urlImages;
  plan: Plan
  datos: Array<DatosObj>
  da1: Array<DatosAct>
  da2: Array<DatosCom>
  nombre: string
  usuario!:{idUsuario:string}
  cargandoPlan = true;
  cargaPlan = false;
  editar = false;
  main=true;
  datocompe: string;
  datocosto: string;
  datodesc: string;
  datofecha:String;
  datoacti: string;


  selectedcompe: string = '';
  nomActividad: string = '';
  descripcion: string = '';
  costo:string = '';
  form: FormGroup = new FormGroup({});
  fecha= new FormControl();


  constructor(private fb: FormBuilder, private router:Router,private service: VerplanserviceService,private rutaActiva: ActivatedRoute) { 
    this.form = fb.group({
      number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      date: [[new Date()]]

    })
  }

  ngOnInit() {
    this.cargandoPlan = true;

    this.service.obtenerActividades().toPromise().then((result: Actividades)=>{
      this.actividades=result; 
      this.da1 = this.actividades.datos   
      this.da2 =this.actividades.datos2
      console.log(this.datos)
    
    })
    
    this.usuario = {
      idUsuario: this.rutaActiva.snapshot.params.idUsuario
    };
    this.rutaActiva.params.subscribe(
      (params: Params)=>{
        this.usuario.idUsuario = params.idUsuario;
        console.log(this.usuario.idUsuario)
        this.idColaborador = (Number(params.idUsuario)/8);
  
      }
    );
    this.service.obtenerResultados(this.idColaborador).toPromise().then((result: Plan)=>{
      this.plan=result; 
      this.datos = this.plan.datos
      console.log(this.datos)
      this.cargandoPlan = false;
      this.cargaPlan = true;
    
    })
    
    
  }
  addActividad(){
    this.nombre = this.plan.nombre
    let urlAdd: string = "addplandesarrollo"+"/"+String(this.idColaborador)+"/"+String(this.nombre)
    this.router.navigate([urlAdd]);
  }

  eliminarActividad(dato){
    let datos: any = {
      idColaborador: this.idColaborador,
      Competencia: dato.COMPETENCIA,
      nombreActividad: dato.TIPODEACTIVIDAD,
      DESCRIPCION: dato.DESCRIPCION,
      COSTO: dato.COSTO,
      FECHA: dato.FECHA
    }
    this.service.eliminarActividad(datos).subscribe( (data) =>{
      console.log(data);

      this.ngOnInit();

    })
    console.log(datos)

  }

  editarActividad(dato){
    window.scrollTo(0,0);
    this.datocompe = dato.COMPETENCIA
    this.selectedcompe = dato.COMPETENCIA
    this.datocosto = dato.COSTO
    this.costo =dato.COSTO
    this.datodesc = dato.DESCRIPCION
    this.descripcion= dato.DESCRIPCION
    this.datofecha = dato.FECHA
    const data =dato.FECHA
    const momentVariable = moment(data, 'MM-DD-YYYY'); 
    const stringvalue = momentVariable.format('YYYY-MM-DD'); 
    this.datoacti = dato.TIPODEACTIVIDAD
    this.nomActividad = dato.TIPODEACTIVIDAD
    this.form = this.fb.group({
      number: [Number(this.datocosto), [Validators.required, Validators.pattern("^[0-9]*$")]],
      date: this.datofecha
    })
    this.main=false;
    this.editar=true;
    console.log(this.datofecha)

  }

  selectcompe(event: any){
    this.selectedcompe = event.target.value;
    console.log(this.selectedcompe)

  }

  selectacti(event:any){
    this.nomActividad = event.target.value;
    console.log(this.nomActividad)

  }

  selectdescri(event: any){
    this.descripcion = event.target.value;
    console.log(this.descripcion)

  }

  selectcosto(event: any){
    this.costo = this.form.value.number;
    console.log(this.costo)
    console.log(this.form.value.number)

  }

  selectfecha(event: any){
    this.fecha = this.form.value.date
    console.log(this.form.value.date)
  }

  enviarActividad(){
    let datosA: any = {
      idColaborador: Number(this.idColaborador),
      Competencia: String(this.selectedcompe),
      nombreActividad: String(this.nomActividad),
      DESCRIPCION: String(this.descripcion),
      COSTO: String(this.costo),
      FECHA: String(this.form.value.date)

    }
    console.log(datosA)
    let urlColaboradores: string = "verplandesarrollo" 
    this.service.addPlan(datosA).subscribe((response) => {
      Swal.fire({
        title: 'Guardado',
        text: "se ha editado exitosamente la actividad",
        icon: 'success',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a plan de desarrollo'
      }).then(()=>{
        this.main=true
        this.editar=false
        this.ngOnInit()
        window.scrollTo(0,0);
      })
      // This is success
  },
  (error: HttpErrorResponse) => {
    Swal.fire({
      title: 'Error',
      text: "Algo ha sucedido, intentalo de nuevo",
      icon: 'error',
      confirmButtonColor: '#0033a1',
      confirmButtonText: 'Volver a intentarlo'
    })
  })
    

  }

  cancelar(){
    this.main=true
    this.editar=false
    let urlVer: string = "verplandesarrollo"
    this.router.navigate([urlVer+"/"+this.idColaborador]);
  }

  get f(){
    return this.form.controls;
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Reporte individual',
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'reportes',
            isLink: true,
            link: '/'
          },
          {
            name: 'colaborador',
            isLink: true,
            link: '/'
          }
        ]
      }
    };
   }

}
