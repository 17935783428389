import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { VerplanserviceService } from '../verplanservice.service';
import { Actividades, DatosAct,DatosCom } from 'app/main/models/planDesarrollo';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';

@Component({
  selector: 'app-addplandesa',
  templateUrl: './addplandesa.component.html',
  styleUrls: ['./addplandesa.component.scss']
})
export class AddplandesaComponent implements OnInit {

  usuario!:{idColaborador:string,nombre: string}
  nombre: string;
  id: number;
  actividades: Actividades
  datos: Array<DatosAct>
  datos2: Array<DatosCom>
  urlImage = environment.urlImages;
  selectedcompe: string = '';
  nomActividad: string = '';
  descripcion: string = '';
  costo:string = '';

  form: FormGroup = new FormGroup({});

  fechaIni1: String;
  fecha= new FormControl();
  dateInicio = new Date();
  dateini: any;

  constructor(private router:Router,private rutaActiva: ActivatedRoute,private service: VerplanserviceService, private fb: FormBuilder) {
    
    this.form = fb.group({
      number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      date: [[new Date(), Validators.required]]

    })
   }

  ngOnInit() {
    console.log(this.dateInicio)
    const ini = this.dateInicio
    const momentVariableIni = moment(ini, 'MM-DD-YYYY'); 
    const stringvalueIni = momentVariableIni.format('YYYY-MM-DD'); 
    this.dateini = stringvalueIni
    this.service.obtenerActividades().toPromise().then((result: Actividades)=>{
      this.actividades=result; 
      this.datos = this.actividades.datos   
      this.datos2 =this.actividades.datos2
      console.log(this.datos)
    
    })
    
    this.usuario = {
      idColaborador: this.rutaActiva.snapshot.params.idColaborador,
      nombre: this.rutaActiva.snapshot.params.nombre
    };
    this.rutaActiva.params.subscribe(
      (params: Params)=>{
        this.usuario.idColaborador = params.idColaborador;
        this.usuario.nombre = params.nombre;

        this.nombre = String(params.nombre)
        this.id = Number(params.idColaborador)
  
      }
    );
  }

  get f(){
    return this.form.controls;
  }

  selectcompe(event: any){
    this.selectedcompe = event.target.value;
    console.log(this.selectedcompe)

  }

  selectacti(event:any){
    this.nomActividad = event.target.value;
    console.log(this.nomActividad)

  }

  selectdescri(event: any){
    this.descripcion = event.target.value;
    console.log(this.descripcion)

  }

  selectcosto(event: any){
    this.costo = this.form.value.number;
    console.log(this.costo)
    console.log(this.form.value.number)

  }
  selectfecha(event: any){
    this.fecha = this.form.value.date
    console.log(this.form.value.date)
  }

  enviarActividad(){
    const data =this.fecha.value
    const momentVariable = moment(data, 'MM-DD-YYYY'); 
    const stringvalue = momentVariable.format('YYYY-MM-DD'); 
    let datosA: any = {
      idColaborador: Number(this.id),
      Competencia: String(this.selectedcompe),
      nombreActividad: String(this.nomActividad),
      DESCRIPCION: String(this.descripcion),
      COSTO: String(this.costo),
      FECHA: String(this.form.value.date)

    }
    let urlColaboradores: string = "verplandesarrollo" 
    this.service.addPlan(datosA).subscribe((response) => {
      Swal.fire({
        title: 'Guardado',
        text: "se ha agregado exitosamente la actividad",
        icon: 'success',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a plan de desarrollo'
      }).then(()=>{
        this.router.navigate([urlColaboradores+"/"+(this.id*8)]);
      })
      // This is success
  },
  (error: HttpErrorResponse) => {
    Swal.fire({
      title: 'Error',
      text: "Algo ha sucedido, intentalo de nuevo",
      icon: 'error',
      confirmButtonColor: '#0033a1',
      confirmButtonText: 'Volver a intentarlo'
    })
  })
    

  }

  cancelar(){
    let urlVer: string = "verplandesarrollo"
    this.router.navigate([urlVer+"/"+(this.id*8)]);
  }

}
