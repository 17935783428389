import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoreoEtapa3Service {

  private endPointMonitoreoEtapa2:string = `${environment.apiMonitoreo}/etapa3`;

  constructor(private _http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

  /**
   * Metodo que obtiene los colaborados para el monitoreo de la etapa 2
   * @param limite
   * @returns 
   */
  obtenerColaboradores(limite:number){
    let params = new HttpParams();
    params = params.append('limite', limite);
    return this._http.get(this.endPointMonitoreoEtapa2, {params:params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }


   /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
