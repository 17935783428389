<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <button type="button" class="btn btn-primary mb-1" rippleEffect (click)="modalOpenSuccess()"
            *ngIf="canLoadPlant && payload == 'Administradores'">
            Cargar Planta
            <span [data-feather]="'upload'" [class]="'mr-25'"></span>
        </button>
        <button type="button" class="btn btn-primary mb-1" rippleEffect (click)="infoCantLoadPlant()"
            *ngIf="!canLoadPlant" disabled>
            Cargar Planta
            <span [data-feather]="'upload'" [class]="'mr-25'"></span>
        </button>
        <button type="button" class="btn btn-primary ml-1 mb-1" rippleEffect (click)="getNovedades()">
            <span [data-feather]="'refresh-cw'" [class]="'mr-25'"></span>
        </button>
        <button type="button" class="btn btn-primary ml-1 mb-1" rippleEffect (click)="sendNovedad('CR')" *ngIf="payload == 'Administradores'">
            <span [data-feather]="'refresh-cw'" [class]="'mr-25'"></span>
            Calcular Resultados
        </button>
        <div class="row" *ngIf="!loadingNovedades">
            <div class="col-md-3 col-sm-6 col-12" *ngFor="let novedad of novedades">
                <div class="card" [ngClass]="{'border-primary': novedad.estado == 'En Proceso', 'border-success': novedad.estado == 'Exitoso', 'border-danger': novedad.estado == 'Rechazado'}" [title]="novedad.proceso == 'CP' ? 'Cargue de Persona' : novedad.proceso == 'RP' ? 'Reporte' : 'Calculo Resultados'">
                    <div class="card-body mx-auto" *ngIf="novedad.proceso == 'CP'">
                        <img src="assets/images/avatars/evaluador.png" class="img-fluid" width="100" height="100"
                            data-placement="left" />
                    </div>
                    <div class="card-body mx-auto" *ngIf="novedad.proceso == 'RP'">
                        <img src="https://i.pinimg.com/originals/cd/ce/71/cdce711f00f0cd5a702f037a0ef1feb8.png" class="img-fluid" width="130" height="130"
                            data-placement="left" />
                    </div>
                    <div class="card-body mx-auto" *ngIf="novedad.proceso == 'CR'">
                        <img src="https://cdn-icons-png.flaticon.com/512/5716/5716795.png" class="img-fluid" width="130" height="130"
                            data-placement="left" />
                    </div>
                    <div class="card-footer">
                        <h6 class="card-subtitle mb-2">Id: {{novedad.idProceso}}</h6>
                        <h6 class="card-subtitle mb-2">Fecha: {{novedad.fecha}}</h6>
                        <h6 class="card-subtitle mb-2">Estado: <div class="badge" [ngClass]="{'badge-primary': novedad.estado == 'En Proceso', 'badge-success': novedad.estado == 'Procesado', 'badge-danger': novedad.estado == 'Rechazado', 'badge-success': novedad.estado == 'Exitoso'}">{{novedad.estado}}</div></h6>
                        <h6 class="card-subtitle mb-2">Descripcion: {{novedad.descripcion}}</h6>
                        <h6 class="card-subtitle mb-2">Proceso: {{novedad.proceso}}</h6>
                        <h6 class="card-subtitle mb-2" *ngIf="novedad.proceso === 'RP'">Tipo: {{novedad.tipo}}</h6>
                        <h6 class="card-subtitle mb-2" *ngIf="novedad.proceso === 'CP'">Total Colaboradores: {{novedad.colaboradores}}</h6>
                        <h6 class="card-subtitle mb-2" *ngIf="novedad.proceso === 'CP'">Total Evaluaciones: {{novedad.evaluaciones}}</h6>
                        <button type="button" class="btn form-control" [ngClass]="{'btn-primary': novedad.estado == 'En Proceso', 'btn-success': novedad.estado == 'Exitoso'}" [id]="novedad.idProceso" (click)="descargarArchivo(novedad.archivo, novedad.idProceso)" rippleEffect title="Descargar Archivo" *ngIf="novedad.proceso === 'RP' && novedad.estado == 'Exitoso' && novedad.idProceso != idButton">
                            Descargar
                            <span [data-feather]="'download'" [class]="'mr-25'"></span>
                        </button>
                        <button class="btn btn-success form-control" type="button" rippleEffect *ngIf="novedad.idProceso == idButton && showDonwload">
                            <span class="mr-25 align-middle">Descargar</span>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-primary form-control" [ngClass]="{'btn-primary': novedad.estado == 'En Proceso', 'btn-success': novedad.estado == 'Exitoso', 'btn-danger': novedad.estado == 'Rechazado'}" (click)="modalInfoDescarga()" rippleEffect [title]="novedad.estado != 'Exitoso' ? 'El archivo no esta listo para la descarga.' : 'Descargar'" disabled *ngIf="novedad.proceso === 'RP' && novedad.estado != 'Exitoso'">
                            Descargar
                            <span [data-feather]="'download'" [class]="'mr-25'"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loadingNovedades">
            <img class="mx-auto d-block" src="../../../../../assets/images/logo/load.gif" alt="">
        </div>
    </div>
</div>

<!--Inicio Modal-->
<div class="modal-dialog modal-xl d-inline-block">
    <ng-template #modalSuccess let-modal>
        <div class="modal-header">
            <h5 class="text-primary modal-title pl-3 pr-3" id="myModalLabel160"><strong>Cargar Planta</strong></h5>
            <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-justify" tabindex="0" ngbAutofocus>
            <div class="custom-file mb-1">
                <input type="file" ng2FileSelect [uploader]="uploader" class="custom-file-input" id="customFile"
                    required>
                <label class="custom-file-label" for="validatedCustomFile">Elija el archivo...</label>
            </div>
            <spam class="text-danger" *ngIf="showErrorExtention">Solo se permiten archivos con extención ".xlsx".</spam>
            <table class="table" *ngIf="uploader.getNotUploadedItems().length">
                <thead>
                    <tr>
                        <th width="50%">Nombre</th>
                        <th>Tamaño</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td>
                            <strong>{{ item?.file?.name }}</strong>
                        </td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>
                            {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="button" disabled rippleEffect *ngIf="submitArchive">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-25 align-middle">Cargando...</span>
            </button>

            <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-primary"
                (click)="uploadFile()" *ngIf="!submitArchive && uploader.getNotUploadedItems().length">
                <span [data-feather]="'upload'" [class]="'mr-25'"></span>Cargar Planta
            </button>

            <button type="button" disabled class="btn btn-primary" *ngIf="!uploader.getNotUploadedItems().length">
                <span [data-feather]="'upload'" [class]="'mr-25'"></span>Cargar Planta
            </button>
        </div>
    </ng-template>
</div>
<!--Fin modal-->