import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { ConfigGeneralServices} from '../configgeneral.service';
import { Configuracion, evaluadores, fechas } from 'app/main/models/responseConfiguracionIni';


@Component({
  selector: 'app-configgeneral',
  templateUrl: './configgeneral.component.html',
  styleUrls: ['./configgeneral.component.scss']
})
export class ConfiggeneralComponent implements OnInit {
  

  contentHeader: object;
  configuraciones: Configuracion;
  datos1:evaluadores;
  datos2:fechas;
  public ColumnMode = ColumnMode;
  limitOptions = [{ key: '6', value: 6 }, { key: '10', value: 10 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 6;
  searchValue = '';
  public selectStatus: any = [
    { name: 'Todo', value: null },
    { name: 'Aprobado', value: 'Aprobado' },
    { name: 'Desaprobado', value: 'Desaprobado' }
  ];
  selectedStatus = null;
  tempData = [];
  isLoadingPage = false;
  cantMini: number =0;
  cantMax: number=0;
  cantJefe: number=0;
  cantPares: number=0;
  cantSub: number=0;
  cantClient: number=0;

  fechaIni1: String;
  fechaIni1Control1= new FormControl();
  fechaIni2: String;
  fechaIni2Control2= new FormControl();
  fechaIni3: String;
  fechaIni3Control3= new FormControl();
  fechaFin1: String;
  fechaFin1Control1= new FormControl();
  fechaFin2: String;
  fechaFin2Control2= new FormControl();
  fechaFin3: String;
  fechaFin3Control3= new FormControl();

  
  cantTotalRoles: number=0;
  datos: any;

  user: FormGroup;


  constructor(private fb: FormBuilder, private service:ConfigGeneralServices ) { }

  

  async ngOnInit(){
    this.getContentHeader();
    await this.service.getConfiguration().toPromise().then((result: Configuracion)=>{this.configuraciones=result});
    this.datos1 = this.configuraciones.evaluadores;
    this.datos2 = this.configuraciones.fechas;
    console.log(this.datos1.pesoPar)

    this.cantMini = Number(this.datos1.cantMin);
    this.cantMax = Number(this.datos1.cantMax);
    this.cantPares = this.datos1.pesoPar;
    this.cantSub = this.datos1.pesoSubalterno;
    this.cantJefe = this.datos1.pesoJefe;
    this.cantClient = this.datos1.pesoCliente

    this.fechaIni1 = this.datos2.inicioEtapa1;
    this.fechaIni2 = this.datos2.inicioEtapa2;
    this.fechaIni3 = this.datos2.inicioEtapa3;
    this.fechaFin1 = this.datos2.finEtapa1;
    this.fechaFin2 = this.datos2.finEtapa2;
    this.fechaFin3 = this.datos2.finEtapa3;

   let fechaIni1Control= new FormControl(this.fechaIni1); 
   let fechaIni2Control= new FormControl(this.fechaIni2); 
   let fechaIni3Control= new FormControl(this.fechaIni3); 
   let fechaFin1Control= new FormControl(this.fechaFin1); 
   let fechaFin2Control= new FormControl(this.fechaFin2); 
   let fechaFin3Control= new FormControl(this.fechaFin3);    

   this.fechaIni1Control1 = fechaIni1Control
   this.fechaIni2Control2 = fechaIni2Control
   this.fechaIni3Control3 = fechaIni3Control
   this.fechaFin1Control1 = fechaFin1Control
   this.fechaFin2Control2 = fechaFin2Control
   this.fechaFin3Control3 = fechaFin3Control
    
    
  }



 
  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Configuración General',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Ajustes',
            isLink: false,
            link: '/configgeneral'
          },
        ]
      }
    }
  }


  minOnChange(value) {
    // this.dateValue = value;
      this.cantMini = Number(value)
    
  }

  maxOnChange(value) {
    // this.dateValue = value;
    this.cantMax = Number(value)
    
    
  }

  jefeOnChange(value) {
    // this.dateValue = value;
    this.cantJefe = Number(value)
    
  }

  paresOnChange(value) {
    // this.dateValue = value;
    this.cantPares = Number(value)
    
  }

  subOnChange(value) {
    // this.dateValue = value;
    this.cantSub = Number(value)
    
  }

  clientOnChange(value) {
    // this.dateValue = value;
    this.cantClient = Number(value)
    
  }

  validacionGuardar(){
    if (this.cantMax == 0){
      Swal.fire({
        icon: 'error',
        title: 'Falta algun valor o esta vacio',
        text: 'la cantidad maxima de evaluadores es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  

    } else if (this.cantMini == 0){
      Swal.fire({
        icon: 'error',
        title: 'Falta algun valor o esta vacio',
        text: 'la cantidad minima de evaluadores es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  
    }else if (this.cantJefe == 0){
      Swal.fire({
        icon: 'error',
        title: 'Algun valor falta o esta vacio',
        text: 'El peso del jefe es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  
    } else if (this.cantSub == 0){
      Swal.fire({
        icon: 'error',
        title: 'Algun valor falta o esta vacio',
        text: 'El peso de los subalternos es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  
    } else if (this.cantPares == 0){
      Swal.fire({
        icon: 'error',
        title: 'Algun valor falta o esta vacio',
        text: 'El peso de los pares es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  
    } else if (this.cantClient == 0){
      Swal.fire({
        icon: 'error',
        title: 'Algun valor falta o esta vacio',
        text: 'El peso de los clientes es 0',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'       
      })  
    }  else {
      this.guardar();
    }
  }


  guardar(){
    const cantTotal = 100;
    this.cantTotalRoles = Number(this.cantClient) + Number(this.cantSub) + Number(this.cantJefe) + Number(this.cantPares)

    if(this.cantMini <= this.cantMax && this.cantTotalRoles 
      == cantTotal){

      let evaluadores: any={
        cantMin: Number(this.cantMini),
        cantMax: Number(this.cantMax),
        pesoJefe: Number(this.cantJefe),
        pesoSubalterno: Number(this.cantSub),
        pesoPar: Number(this.cantPares),
        pesoCliente: Number(this.cantClient)
      }
  
      let fechas: any ={
        inicioEtapa1: this.fechaIni1Control1.value,
        finEtapa1: this.fechaFin1Control1.value,
        inicioEtapa2: this.fechaIni2Control2.value,
        finEtapa2: this.fechaFin2Control2.value,
        inicioEtapa3: this.fechaIni3Control3.value,
        finEtapa3: this.fechaFin3Control3.value
        
      }
  
      let datosConfig: any ={
        evaluadores,
        fechas
      }

      this.datos = datosConfig
  
      console.log(datosConfig)

      this.service.patchConfig(this.datos).subscribe()

      Swal.fire({
        title: 'Guardado Con Exito',
        icon: 'success',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Continuar'
      })

    } else if(this.cantMini > this.cantMax){
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'la cantidad minima de los evaluadores es mayor a la cantidad maxima',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'
        
      })  

    
    } else if(this.cantTotalRoles !== cantTotal){
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'El peso de los evaluadores no es correcto',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Volver a configuración'
        
      }) 

    } 
  }

  
  

  



}
