import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigGeneralServices } from 'app/main/dashboard/etapa1/configuracion/configgeneral.service';
import { Evaluado,DatosObj } from 'app/main/models/evaluado';
import { requestObtenerpregunta,DatosRes } from 'app/main/models/requestObtenerpregunta';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { VisualizarEvaluadosServices } from './visualizar-evaluados.service';
import { FormGroup } from '@angular/forms';
import { RevisarPostulacionesService } from '../../../etapa1/revisar-postulaciones/revisar-postulaciones/revisar-postulaciones.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';



@Component({
  selector: 'app-visualizar-evaluados',
  templateUrl: './visualizar-evaluados.component.html',
  styleUrls: ['./visualizar-evaluados.component.scss']
  
})
export class VisualizarEvaluadosComponent implements OnInit {
  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  contentHeader2: object;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  searchValue = '';
  public selectStatus: any = [
    { name: 'Finalizado', value: 'Finalizado' },
    { name: 'En Proceso', value: 'En Proceso' },
    { name: 'Pendiente', value: 'Pendiente' }
  ];
  pregunta: requestObtenerpregunta
  datosRes:DatosRes
  datosR: DatosRes

  selectedStatus = null;
  tempData = [];
  rows:Evaluado[] = [];
  isLoadingPage = false;
  evaluado:Evaluado;
  datos: Array<DatosObj>[];
  evaluadoDesaprobado: Evaluado;
  attributes360:any;
  formDesaprobar:FormGroup;

  
  urlEvaluacion: string;
  idEvaluado: number ;
  nombreEvaluado: String;
  relacionTrabajo: String;
  motivo: String;
  idEvaluador: number ;

  showMotivo = false;
  showEvaluacion = false;
  rechazando = true;
  datosMotivo: any;

  id_evaluadoEva: string;
  id_evaluadorEva: string;
  nombreEvaluadoEva: string;

  payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private router:Router,  private visualizarEvaluadosService:VisualizarEvaluadosServices,
              private _configuracionService:ConfigGeneralServices,  private revisarPostulacionesService:RevisarPostulacionesService, private alert:SweetAlertService) {}

  ngOnInit() {
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this.idEvaluador = Number(this.attributes360['preferred_username']);
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 1") {
          if(this.payload == "Administradores"){
            this.initContentHeadersAndEvaluadores();
          }else{
            this.alert.alertNotification('info', 'La etapa 2 estará activa desde ' + data['fechas']['inicioEtapa2'] + ' hasta ' + data['fechas']['finEtapa2'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initContentHeadersAndEvaluadores();
        }
      }
    },err => {
      console.log(err);
      this.alert.alertNotification('error', 'Error al obtener la etapa actual', 3000);
    })
  }

  initContentHeadersAndEvaluadores(){
    this.getContentHeader();
    this.getContentHeader2();
    this.obtenerEvaluadores();
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Realiza la Evaluación de desempeño 360º',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: false,
            link: '/evaluacion/evaluados'
          },
        ]
      }
    }
  }

  getContentHeader2(){
    this.contentHeader2 = {
      headerTitle: 'Realiza la Evaluación de desempeño 360º',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inhabilitar',
            isLink: false,
            link: '/evaluacion/evaluados'
          },
        ]
      }
    }
  }

  cancelarDesaprobar(){
    this.showMotivo = false;
    this.rechazando = false;
    this.idEvaluado = null;
    this.nombreEvaluado = null;
    this.relacionTrabajo = null;
  }

  desabilitarEvaluador(idEvaluado,nombreEvaluado,relacionTrabajo,estadoEvaluacion){
    if(estadoEvaluacion == "Finalizado"){
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'No se puede deshabilitar',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Continuar'      
      }) 

    }else{
      this.showMotivo = true
      this.idEvaluado = idEvaluado;
      this.nombreEvaluado = nombreEvaluado;
      this.relacionTrabajo = relacionTrabajo;
    }
    
  }

  doTextareaValueChange(ev) {
    try {
      this.motivo = ev.target.value;
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombreEvaluado && d.nombreEvaluado.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : null;
    if (filterStatus != null) filterStatus =  filterStatus;

    filterAllData = filterAllData.filter(row => {
      return (row.estadoEvaluacion === filterStatus) || filterStatus == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  

  guardarCambio(){
    this.datosMotivo = {
      ID_evaluado: this.idEvaluado,
      motivoInactivacion: this.motivo,
      ID_evaluador: Number(this.attributes360['preferred_username']),
      ID_revisor: Number(this.attributes360['preferred_username'])

    }
    this.visualizarEvaluadosService.patchDeshabilitar(this.datosMotivo).subscribe(data =>{
      console.log(data.Error)
      if(data.Error){
        Swal.fire({
          title: 'Error',
          text: data.Error,
          icon: 'error'
        }).then(result => {
          this.cancelarDesaprobar();
          location.reload();
        })

      } else {
        Swal.fire({
          title: 'Guardado',
          text: data.Mensaje,
          icon: 'success'
        }).then(result => {
          this.cancelarDesaprobar();
          location.reload();
        })

      }
    })
  }

  obtenerEvaluadores(){
    this.isLoadingPage = true;
    this.visualizarEvaluadosService.getEvaluadosPorIdUsuario(Number(this.attributes360['preferred_username'])).subscribe(data => {
      if(data != null){
        this.rows = data as Evaluado[];
        this.tempData = this.rows;
        this.isLoadingPage = false;
        console.log(this.rows)
      }
    }, err =>{
      console.log(err);
      this.isLoadingPage = false;
    })
  }

  async goToEvaluacion(ID_evaluado ,ID_evaluador,nombreEvaluado,estadoEvaluacion){
    this.id_evaluadoEva = ID_evaluado.toString();
    this.id_evaluadorEva = ID_evaluador.toString();
    this.nombreEvaluadoEva = nombreEvaluado;

    if(estadoEvaluacion == "Finalizado"){
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'Has finalizado la evaluación',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Continuar'
        
      })  

    }else if(estadoEvaluacion == "En Proceso"){
      await this.visualizarEvaluadosService.getQuestionProgreso(ID_evaluado,ID_evaluador).toPromise().then((result: requestObtenerpregunta)=>{this.pregunta=result})
      this.datosR = this.pregunta.datos
      let idCompetencia = this.datosR.idCompetencia
      console.log(this.pregunta.datos.idCompetencia)
            
      Swal.fire({
        icon: 'warning',
        title: 'La evaluación esta en proceso',
        text: 'Desea continuar?',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Continuar'     
      }).then(()=>{
        if(idCompetencia == 7){
          let competenciap = "Innovación";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);

        } else if(idCompetencia == 6){
          let competenciap = "Desempeño Superior";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);
          
        }else if(idCompetencia == 5){
          let competenciap = "Corresponsabilidad";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);
          
        }else if(idCompetencia == 4){
          let competenciap = "Liderazgo Transformador";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);
          
        }else if(idCompetencia == 3){
          let competenciap = "Agilidad Organizacional";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);
          
        }else if(idCompetencia == 2){
          let competenciap = "Flexibilidad al Cambio";
      this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
        this.router.navigate([this.urlEvaluacion]);
          
        }
       
      })
      
    } else if(estadoEvaluacion == "Pendiente") {
      this.showEvaluacion = true;
    } else if (estadoEvaluacion == ""){
      Swal.fire({
        icon: 'error',
        title: 'El evaluado no tiene estado de evaluación',
        confirmButtonColor: '#0033a1',
        confirmButtonText: 'Continuar'
        
      })
    }
  }

  iniciarEvaluacion(){
    let competenciap = "Desempeño del Cargo";
    this.urlEvaluacion = 'evaluacion/360/'+this.id_evaluadoEva.toString()+"/"+this.id_evaluadorEva.toString()+"/"+this.nombreEvaluadoEva+"/"+competenciap;   
    this.router.navigate([this.urlEvaluacion]);
  } 


  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
  
}