import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, of, BehaviorSubject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { User } from 'app/auth/models';
import { map, catchError } from 'rxjs/operators';
import {awsmobile} from 'aws-exports';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public loggedIn: BehaviorSubject<boolean>;
  menu: any[];

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private route:Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          if (this.getAttributes() == null) {
              if (result && result['attributes']) {
                this.saveAttributes(result['attributes']);
                this.savePayload(result.signInUserSession.idToken.payload); 
                let payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
                let ruta = payload === "Administradores" ? 'configgeneral' : payload === "Jefes" ? 'postulaciones' : 'evaluadores';
                this.route.navigate([ruta]);
              }
          }
          
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          //console.log("error");
          //console.log(error);
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * 
   * @param username 
   * @returns 
   */
  forgotPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  /**
   * 
   * @param username 
   * @param codigo 
   * @param password 
   * @returns 
   */
  forgotPasswordSubmit(username: string, codigo: string, password: string) {
    return Auth.forgotPasswordSubmit(username, codigo, password);
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  login(username: string, password: string) {
    return Auth.signIn(username, password);
  }

  savePayload(atributos) {
    localStorage.setItem('payload', JSON.stringify(atributos));
  }

  getPayload() {
    return JSON.parse(localStorage.getItem('payload'));
  }

  saveAttributes(atributos) {
    localStorage.setItem('attributes360', JSON.stringify(atributos));
  }

  getAttributes() {
    return JSON.parse(localStorage.getItem('attributes360'));
  }

  saveAttrLogoutCognito(value) {
    localStorage.setItem('logoutCognito', value);
  }

  getAttrLogoutCognito() {
    return JSON.parse(localStorage.getItem('logoutCognito'));
  }

  /**
   * 
   * @param user 
   * @param password 
   * @returns 
   */
  completeNewPassword(user, password: string) {
    return Auth.completeNewPassword(user, password);
  }

  /**
   * 
   * @param user 
   * @param code 
   * @returns 
   */
  confirmSignIn(user, code:string) {
    return Auth.confirmSignIn(user, code,"SMS_MFA");
  }

  /**
   * Logout Account in Microsoft
   *
   */
  public logoutMicrosoft() {
    this.saveAttrLogoutCognito('true');
    let attr = this.getAttributes();
    if (attr && attr['nickname'])
      window.location.replace("https://login.microsoftonline.com/"+attr['nickname']+"/oauth2/logout?post_logout_redirect_uri="+awsmobile.oauth.redirectSignOut+"/pages/authentication/login-v1");
    //window.location.replace("https://login.microsoftonline.com/"+attr['nickname']+"/oauth2/logout?post_logout_redirect_uri=http://localhost:4200/pages/authentication/login-v1/logout");
  }

  /**
   * Logout Account in Cognito
   *
   */
  public validateLogoutCognito() {
    let aux = this.getAttrLogoutCognito();
    if (aux && (aux == true || aux == 'true') ) {
      from(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          localStorage.removeItem('attributes360');
          this.saveAttrLogoutCognito('false');
        },
        error => console.log(error)
      );
    }
  }

}
