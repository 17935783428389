<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card">
    <div class="modal-content p-0">
        <div class="card-body">
            <form class="form form-horizontal" [formGroup]="formBuscar">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="nombre" placeholder="Nombre del colaborador" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary" (click)="obtenerEvaluadores()">
                                    <i data-feather="search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div *ngIf="cargandoEvaluadores" class="my-2 d-flex align-items-center justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div *ngIf="notFoundEvaluadores" class="my-1">
                            No se encontraron resultados. 
                        </div>
                        <div class="card card-employee-task m-0">
                            <div class="card-header" *ngIf="!cargandoEvaluadores && posiblesEvaluadores?.length > 0">
                                <h4 class="card-title">Colaboradores</h4>
                            </div>
                            <table class="table table-borderless" *ngIf="!cargandoEvaluadores && posiblesEvaluadores?.length > 0">
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>AGREGAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let evaluador of posiblesEvaluadores">
                                        <td class="pr-0 pl-0">
                                            <div class="media">
                                                <div class="avatar mr-75">
                                                    <img src="{{urlImage}}{{evaluador.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                    class="" width="42" height="42" data-placement="left"/>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h6 class="mb-0">{{evaluador.nombre}}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-center">
                                            <button class="btn btn-primary btn-sm" (click)="agregarEvaluador(evaluador, 'PAR')">PAR</button>
                                            <button class="btn btn-primary btn-sm mx-1" (click)="agregarEvaluador(evaluador, 'CLIENTE')">CLIENTE</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 d-flex justify-content-center align-items-center" *ngIf="agregandoEvaluador">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 " *ngIf="!agregandoEvaluador && evaluadores.length > 0">
                        <div class="card card-employee-task">
                            <div class="card-header pr-0 pl-0">
                                <h4 class="card-title">Evaluadores</h4>
                            </div>
                            <table class="table table-borderless" *ngIf="posiblesEvaluadores.length > 0">
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>ELIMINAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let evaluador of evaluadores; let i = index">
                                        <td class="pr-0 pl-0">
                                            <div class="media">
                                                <div class="avatar mr-75">
                                                    <img src="{{urlImage}}{{evaluador.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                    class="" width="42" height="42" data-placement="left"/>
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h6 class="mb-0">{{evaluador.nombre}}</h6>
                                                    <span class="text-secondary">{{evaluador.tipo}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-center">
                                            <button type="button" (click)="devolverEvaluador(evaluador, i)"
                                                class="btn btn-icon btn-icon rounded-circle btn-danger"
                                                title="Eliminar a {{evaluador.nombre_colaborador}}">
                                                <span [data-feather]="'x-circle'"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center" *ngIf="!cargandoEvaluadores && !agregandoEvaluador && posiblesEvaluadores?.length > 0 && evaluadores?.length == 0">
                        <ngb-alert [type]="'warning'" [dismissible]="false">
                            <h4 class="alert-heading">No hay evaluadores</h4>
                            <div class="alert-body">
                                Debe buscar y seleccionar los evaluadores que desee
                            </div>
                        </ngb-alert>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-sm-6">
                        <button class="btn btn-primary" (click)="goToEvaluadores()">Volver</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>