import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Colaborador } from 'app/main/models/colaborador';
import { RequestAddEvaluador } from 'app/main/models/requestAddEvaluador';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { ConfigGeneralServices } from '../../configuracion/configgeneral.service';
import { RegistroEvaluadoresService } from '../registro-evaluadores.service';

@Component({
  selector: 'app-agregar-evaluador',
  templateUrl: './agregar-evaluador.component.html',
  styleUrls: ['./agregar-evaluador.component.scss']
})
export class AgregarEvaluadorComponent implements OnInit {
  urlImage = environment.urlImages;
  contentHeader: object;
  formBuscar:FormGroup;
  posiblesEvaluadores:Colaborador[];
  evaluadores:Colaborador[];
  cargandoEvaluadores = false;
  notFoundEvaluadores = false;
  agregandoEvaluador = false;
  attributes360:any;
  estadoAprobado = 'Aprobado';
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
  
  constructor(private formBuilder:FormBuilder, private alert:SweetAlertService,
              private router:Router, private registroEvaluadoresService:RegistroEvaluadoresService,
              private _configuracionService:ConfigGeneralServices) { }

  ngOnInit(): void {
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this.getContentHeader();
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 2"){
          if(this.payload != "Administradores"){
            this.router.navigate(['evaluadores']);
            this.alert.alertNotification('info', 'No se permite registrar evaluadores en la etapa 2', 3000);
          }
        }
      }
    },err  => {
      console.log(err);
      this.router.navigate(['evaluadores']);
    })
    this.formBuscar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })
  }

  getContentHeader(){
    this.evaluadores = [];
    this.contentHeader = {
      headerTitle: 'Agregar evaluadores',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: true,
            link: '/evaluadores'
          },
          {
            name: 'Agregar',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
  }

  obtenerEvaluadores(){
    this.cargandoEvaluadores = true;
    if(this.formBuscar.controls['nombre'].value != ''){
      this.registroEvaluadoresService.obtenerColaboradorerPorNombre(this.formBuscar.controls['nombre'].value).subscribe(data => {
        if(data != null){
          if(data['datos'].length > 0 && data['codRespuesta'] === 0){
            this.posiblesEvaluadores = data['datos'] as Colaborador[];
            this.cargandoEvaluadores = false;
          }
        }
      },err =>{
        console.log(err);
        if(err['error']['codRespuesta'] === 90){
          this.notFoundEvaluadores = true;
          this.cargandoEvaluadores = false;
          this.posiblesEvaluadores = [];
        }
      })
        this.notFoundEvaluadores = false;
    }else{
      this.posiblesEvaluadores = [];
      this.cargandoEvaluadores = false;
      this.notFoundEvaluadores = false;
    }
  }

  agregarEvaluador(evaluador:Colaborador, tipo:string){
    if(this.attributes360['preferred_username']){
      this.agregandoEvaluador = true;
      evaluador.tipo = tipo;
      let requestAddEvaluador = new RequestAddEvaluador();
      requestAddEvaluador.ID_evaluado = Number(this.attributes360['preferred_username']);
      requestAddEvaluador.ID_evaluador = evaluador.ID_colaborador;
      requestAddEvaluador.nombreEvaluador = evaluador.nombre;
      requestAddEvaluador.relacionEvaluador = evaluador.tipo;
      requestAddEvaluador.aprobacion = this.estadoAprobado;
      requestAddEvaluador.areaEvaluador = this.attributes360['locale'] && this.attributes360['localte'] !== null ? this.attributes360['localte'] : '';
      this.registroEvaluadoresService.asociarEvaluador(requestAddEvaluador).subscribe(data => {
        if(data['codRespuesta'] === 0){
          this.alert.alertNotification('success', 'Evaluador agregado!', 1500);
          this.evaluadores.push(evaluador);       
        }else if(data['codRespuesta'] === 8){
          this.alert.alertNotification('info',  evaluador['nombre'] + ' ya hace parte de su lista de evaluadores', 3000);
        }else if(data['codRespuesta'] === 12){
          this.alert.alertNotification('info',  evaluador['nombre']+ ' se encuentra en estado Rechazado', 3000);
        }
        this.agregandoEvaluador = false;
      },err =>{
        console.log(err);
        this.alert.alertNotification('error', 'Error al agregar el evaluador', 3000);
        this.agregandoEvaluador = false;
      })
    }
  }

  devolverEvaluador(evaluador:Colaborador, i:number){
    if(this.attributes360['preferred_username']){
      Swal.fire({
        title: 'Eliminar',
        text: "¿Desea eliminar a " + this.evaluadores[i]['nombre'] + " de su lista de Evaluadores?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0033a1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.registroEvaluadoresService.eliminarEvaluador(this.attributes360['preferred_username'], evaluador.ID_colaborador).subscribe(data => {
            if(data != null && data['codRespuesta'] === 0){
              this.evaluadores.splice(i, 1);
              this.alert.alertNotification('success', 'Evaluador eliminado!', 1500);
            }
          },err => {
            console.log(err);
          })
        }
      })
    }
  }

  goToEvaluadores(){
    this.router.navigate(['evaluadores']);
  }
}
