import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerplanserviceService {
  private endPointVerPlan:string = `${environment.apiVerPlanDesarrollo}`;
  private endPointActividades:string = `${environment.apiActividades}`;
  private endPointaddActividad:string = `${environment.apiAddActividades}`;

  constructor(private _http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

  obtenerResultados(idColaborador:number){
    let params = new HttpParams();
    params = params.append('idColaborador', idColaborador);
    return this._http.get(this.endPointVerPlan, {params:params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )

  }

  addPlan(datos){
    let params = new HttpParams();
    return this._http.post(this.endPointaddActividad, datos, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )

  }

  eliminarActividad(datos){
    console.log("AQUIIIIIII")
    console.log(datos)
    let params = new HttpParams();
    return this._http.request('delete',this.endPointaddActividad,{headers:this.getHeaders(), body: datos} ).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )

  }

  obtenerActividades(){
    let params= new HttpParams().set('lista',"Area");
    return this._http.get(this.endPointActividades+'?'+ "Lista="+"Actividades",{headers:this.getHeaders()} ).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )

  }


   /**
  * Catch errors on call WS
  * @param error
  */
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('Ocurrió un error:', error.error.message);
      }
      return throwError(error);
    }
}
