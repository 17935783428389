import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivarEvaluacion } from 'app/main/models/requestActivarEvaluacion';
import { RechazarEvaluacion } from 'app/main/models/requestRechazarEvaluacion';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {

  endPointEvaluciones:string = `${environment.apiMonitoreo}/evaluaciones`;
  endPointMonitoreo:string = environment.apiMonitoreo;

  constructor(private _http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }


  /**
   * Metodo que obtiene las primeras 200 evaluaciones
   * @param limite 
   * @returns 
   */
  obtenerPrimerasEvaluaciones(){
    return this._http.get(this.endPointEvaluciones, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Metodo que obtiene las evaluaciones faltantes
   * @param idEvaluador 
   * @param idEvaluado 
   * @returns 
   */
  obtenerEvaluaciones(idEvaluador:number, idEvaluado:number){
    
    let params = new HttpParams();
    params = params.append('ID_evaluador', idEvaluador);
    params = params.append('ID_evaluado', idEvaluado);
    return this._http.get(this.endPointEvaluciones, {params:params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Metodo que rechaza una evaluacion
   * @param evaluacionRechazada 
   * @returns 
   */
  rechazarEvaluacion(evaluacionRechazada:RechazarEvaluacion){
    return this._http.patch(this.endPointEvaluciones, evaluacionRechazada, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }
  
  /**
   * Metodo que activa una evaluacion
   * @param evaluacionActivada 
   * @returns 
   */
  activarEvaluacion(evaluacionActivada:ActivarEvaluacion){
    return this._http.patch(this.endPointMonitoreo, evaluacionActivada, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
  * Catch errors on call WS
  * @param error
  */
   private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
