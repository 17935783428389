import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Evaluador } from 'app/main/models/evaluador';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ConfigGeneralServices } from '../../configuracion/configgeneral.service';
import { RegistroEvaluadoresService } from '../registro-evaluadores.service';

@Component({
  selector: 'app-registro-evaluadores',
  templateUrl: './registro-evaluadores.component.html',
  styleUrls: ['./registro-evaluadores.component.scss']
})
export class RegistroEvaluadoresComponent implements OnInit, OnDestroy {

  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  searchValue = '';
  public selectStatus: any = [
    { name: 'Todo', value: null },
    { name: 'Aprobado', value: 'Aprobado' },
    { name: 'Rechazado', value: 'Rechazado' }
  ];
  selectedStatus = null;
  tempData = [];
  rows:Evaluador[] = [];
  isLoadingPage = false;
  suscription:Subscription;
  attributes360:any;
  anadirActivo;
  enableEliminar = true;
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private router:Router, private registroEvaluadoresService:RegistroEvaluadoresService,
              private alert:SweetAlertService, private _configuracionService:ConfigGeneralServices) {}

  ngOnInit(): void {
    
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(this.payload == "Administradores"){
          this.anadirActivo = true;
        }else{
          this.anadirActivo = data['etapaActual'] === "Etapa 1" ? true : false;
          if(data['etapaActual'] === "Etapa 2"){
            this.enableEliminar = false;
          }
        }
      }
    },err => {
      console.log(err);
    })
    this.getContentHeader();
    this.obtenerEvaluadores();
    this.suscription = this.registroEvaluadoresService.refresh$.subscribe(() => {
      this.obtenerEvaluadores();
    })
  }

  ngOnDestroy(){
    this.suscription.unsubscribe();
    console.log("Observable cerrado");
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Registro de evaluadores',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: false,
            link: '/evaluadores'
          },
        ]
      }
    }
  }

  obtenerEvaluadores(){
    if(this.attributes360['preferred_username']){
      this.isLoadingPage = true;
      this.registroEvaluadoresService.getEvaluadoresPorIdUsuario(this.attributes360['preferred_username'], 10).subscribe(data => {
        if(data != null){
          this.rows = data['datos'] as Evaluador[];
          this.tempData = this.rows;
          this.isLoadingPage = false;
        }
      }, err =>{
        console.log(err);
        this.isLoadingPage = false;
      })
    }
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombreEvaluador && d.nombreEvaluador.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : null;
    if (filterStatus != null) filterStatus =  filterStatus;

    filterAllData = filterAllData.filter(row => {
      return (row.aprobacion === filterStatus) || filterStatus == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  eliminarEvaluador(evaluador:Evaluador){
    if(evaluador.eliminar === 0){
      Swal.fire({
        title: 'Eliminar',
        text: "¿Desea eliminar a " + evaluador.nombreEvaluador + " de su lista de Evaluadores?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0033a1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.registroEvaluadoresService.eliminarEvaluador(evaluador.ID_evaluado, evaluador.ID_evaluador).subscribe(data => {
            if(data != null && data['codRespuesta'] === 0){
              this.alert.alertNotification('success', 'Evaluador eliminado!', 1500);
            }
          },err => {
            console.log(err);
          })  
        }
      })
    }else{
      this.alert.alertNotification('info', 'No se puede eliminar este evaluador', 3000);
    }
  }

  goToAgregarEvaluador(){
    this.router.navigate(['evaluadores/agregar']);
  }

  errorEliminar(){
    this.alert.alertNotification('info', 'No se pueden eliminar evaluadores en la etapa 2', 3000);
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}