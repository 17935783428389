<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card" >
    <div class="modal-content p-0">
        <div class="card-body">
            <form class="form form-horizontal" [formGroup]="formBuscar">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="searchValue" formControlName="nombre"
                                placeholder="Nombre de área"  (keyup)="filterAll()"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary">
                                    <i data-feather="search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="card card-employee-task">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-header">
                                    <h4 class="card-title">Lista de áreas   </h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="col-md-3 col-md-12 d-flex d-flex justify-content-end">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <div class="d-flex align-items-center">
                                            <label class="d-flex align-items-center">Ver&nbsp;
                                                <select class="form-control mx-20" [(ngModel)]="selectedOption"
                                                    (ngModelChange)="changePageSize($event)">
                                                    <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">
                                                        {{opt.key}}</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable #table [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap"
                            [limit]="selectedOption" [columnMode]="ColumnMode.force" [headerHeight]="120"
                            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="true" [messages]="{emptyMessage: ''}">
                            <ngx-datatable-column name="Nombre" prop="nombre" [width]="250">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="d-flex">
                                        
                                        <div class="columnNombre">
                                            {{row?.nombre}}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Ver" [width]="50">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <button type="button" (click)="reporteArea(row)"
                                        class="btn btn-icon rounded-circle btn-outline-primary"
                                        title="Evaluadores de {{row?.nombre}}">
                                        <span [data-feather]="'arrow-right'"></span>
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                    let-offset="offset">
                                    <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                                        Total de registros {{rowCount}}
                                    </div>
                                    <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                                        No hay registros ...
                                    </div>
                                    <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                                        Cargando información...
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage"
                                        [size]="pageSize" (change)="onChange($event)"
                                        [hidden]="!((rowCount / pageSize) > 1)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
