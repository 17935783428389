import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/auth/service/authentication.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit{
  // Public

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private authService: AuthenticationService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

  }
}
