import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestDesaprobar } from 'app/main/models/requestDesaprobar';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RevisarPostulacionesService {

  endPointEvaluadores:string = `${environment.apiGeneral}/evaluadores`;
  endPointPersonas:string = `${environment.apiGeneral}/personas`;
  endPointEvaluados:string = `${environment.apiGeneral}/evaluados`;

  constructor(private http:HttpClient) { }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

  /**
   * Metodo que obtiene los colaboradores dependiendo del nombre
   * @param nomUsuario 
   * @returns 
   */
  obtenerColaboradorerPorNombre(nomUsuario:string){
    let params = new HttpParams();
    params = params.append('nomUsuario', nomUsuario);
    return this.http.get(this.endPointPersonas, {params:params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Obtiene lista de evaluadores por idUsuario
   * @param idUsuario
   * @param limite 
   * @returns 
   */
   getEvaluadoresPorIdUsuario(idUsuario:number, limite:number){
    let params= new HttpParams();
    params = params.append('idEvaluado', idUsuario);
    params = params.append('estado', 'Aprobado')
    return this.http.get(this.endPointEvaluadores + '/subalternos', {params: params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Metodo que obtiene los subalternos de un jefe
   * @param idJefe 
   * @param limite 
   * @returns 
   */
  obtenerSubalternosPorIdJefe(idJefe:number, limite:number){
    let params =new HttpParams();
    params = params.append('idJefe', idJefe);
    // params = params.append('limite', limite);
    return this.http.get(this.endPointPersonas + '/subalternos', {params: params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Metodo que desaprueba a un evaluador de un subalterno
   * @param request 
   */
  desaprobarEvaluadorSubalterno(request:RequestDesaprobar){
    return this.http.patch(this.endPointEvaluados, request, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

   /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
